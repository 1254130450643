import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-table-card',
  templateUrl: './table-card.component.html',
  styleUrls: ['./table-card.component.scss'],
})
export class TableCardComponent {
  @Input() tableHeaders: any;
  @Input() rows: any;
  @Input() name: any;
  @Output() actionSelected = new EventEmitter<any>();
  @Input() isSearching: any;
  @Input() noDataError: any;
  @Input() setSelected: any;
  p: any;

  @Input() serverSidePagination: boolean;
  @Input() totalRows;
  @Output() setPageEvent = new EventEmitter();

  constructor() {
    this.p = 1;
  }

  selectCard(id: any) {
    // new pagination
    if (id > 5) return;

    let currentID = '';
    for (let index = 0; index < this.rows.length; index++) {
      currentID = 'card-' + this.name + index;
      document.getElementById(currentID)?.classList.remove('active');
    }

    let ele: any = document.getElementById('radio-' + this.name + id);
    ele.checked = true;
    document.getElementById('card-' + this.name + id)?.classList.add('active');

    const i = (this.p - 1) * 5 + id;
    if (this.rows[i]) this.actionSelected.emit([this.rows[i]]);
  }

  checkSelectable(event: any) {
    console.log('Checking if selectable', event);
    return event.name !== 'Claudine Neal';
  }

  resetCards() {
    for (let index = 0; index < this.rows.length; index++) {
      const currentID = 'card-' + this.name + index;
      document.getElementById(currentID)?.classList.remove('active');
    }

    for (let index = 0; index < this.rows.length; index++) {
      const currentID = 'radio-' + this.name + index;

      let ele: any = document.getElementById(currentID);
      if (ele) ele.checked = false;
    }
  }

  ngAfterViewInit(): void {
    if (this.setSelected != undefined) {
      // this.selectCard(this.setSelected);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.setSelected && !changes.setSelected.firstChange) {
      if (this.setSelected != undefined) {
        this.selectCard(this.setSelected);
      }
    }

    if (changes.isSearching) {
      this.resetCards();
    }
  }

  setPage(e) {
    this.p = e;
    this.setPageEvent.emit({ offset: e - 1 });
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FileSystemDirectoryEntry,
  FileSystemFileEntry,
  NgxFileDropEntry,
} from 'ngx-file-drop';

@Component({
  selector: 'app-drag-ndrop-multi',
  templateUrl: './drag-ndrop-multi.component.html',
  styleUrls: ['./drag-ndrop-multi.component.scss'],
})
export class DragNdropMultiComponent implements OnInit {
  @Input() type: any;
  @Input() title: any;
  @Input() info: any;
  @Input() required: any;
  @Input() format: any;
  @Output() fileObj = new EventEmitter<any>();
  // @Input() file: any;

  file: any = {
    name: '',
    size: '',
  };

  arrayFiles: any = [];

  public files: NgxFileDropEntry[] = [];

  constructor() {
    this.type = 'load';
  }

  ngOnInit(): void {}

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;

    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          var sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
          this.file.name = file.name;
          this.file.size = sizeInMB;

          this.fileObj.emit(file);
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  fileLeave(e: any) {
    // console.log(e);
  }

  saveFile() {
    // this.download('test.txt', 'Hello world!');
    this.download(this.file.name, this.file);
  }

  download(filename: any, text: any) {
    var pom = document.createElement('a');
    pom.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
    );
    pom.setAttribute('download', filename);

    if (document.createEvent) {
      var event = document.createEvent('MouseEvents');
      event.initEvent('click', true, true);
      pom.dispatchEvent(event);
    } else {
      pom.click();
    }
  }

  delete(i) {
    console.log(i);
    this.arrayFiles.splice(i, 1);
  }
}

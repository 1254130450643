<div class="main" *ngIf="type === 'template'">
  <div class="centered">
    <div class="buttons">
      <div class="action-button">
        <i class="far fa-file-alt"></i>
      </div>
    </div>
    <div class="title">
      {{ title }}
    </div>
  </div>
</div>

<!-- ************* LOAD ************* -->
<ngx-file-drop
  dropZoneLabel="Drop files here"
  (onFileDrop)="dropped($event)"
  (onFileLeave)="fileLeave($event)"
  *ngIf="!file?.name && type === 'load'"
  class="dnd-docs"
>
  <ng-template
    ngx-file-drop-content-tmp
    let-openFileSelector="openFileSelector"
  >
    <div class="center-container">
      <div class="buttons">
        <div class="action-button" (click)="openFileSelector()">
          <i class="fas fa-paperclip"></i>
          <p class="info">
            {{ info }}
          </p>
          <p class="text-bold">{{ format }}</p>
        </div>
      </div>
      <div class="title">
        {{ title }}
      </div>
    </div>
  </ng-template>
</ngx-file-drop>

<div class="main loaded" *ngIf="file.name != ''">
  <div class="centered">
    <div class="buttons">
      <div class="action-button checked">
        <i class="fas fa-check-circle"></i>
      </div>
    </div>
    <div class="title">
      <span>{{ file.name }} </span>
      <p>{{ file.size }} MB</p>
    </div>
  </div>
</div>
<!-- ************* LOAD ************* -->

<!-- ************* DOWNLOAD ************* -->
<ngx-file-drop
  dropZoneLabel="Drop files here"
  (onFileDrop)="dropped($event)"
  (onFileLeave)="fileLeave($event)"
  *ngIf="!file?.name && type === 'load-download'"
  class="dnd-docs"
>
  <ng-template
    ngx-file-drop-content-tmp
    let-openFileSelector="openFileSelector"
  >
    <div class="center-container">
      <div class="buttons">
        <div class="action-button" (click)="openFileSelector()">
          <i class="fas fa-paperclip"></i>
        </div>
        <abbr data-title="Descargar plantilla">
          <a>
            <div class="action-button download">
              <i class="fas fa-download"></i>
            </div>
          </a>
        </abbr>
      </div>
      <div class="title">
        {{ title }}
      </div>
    </div>
  </ng-template>
</ngx-file-drop>
<!-- ************* DOWNLOAD ************* -->

<div *ngIf="!noDataError">
  <ngx-datatable
    class="material"
    [rows]="_rows"
    [columnMode]="ColumnMode.force"
    [headerHeight]="50"
    [footerHeight]="50"
    rowHeight="auto"
    [limit]="entriesVal"
    [selected]="selected"
    [selectionType]="SelectionType.single"
    (activate)="onActivate($event)"
    (select)="onSelect($event)"
    [selectCheck]="checkSelectable"
    [messages]="{
      emptyMessage: 'No se encontraron pólizas disponibles',
      totalMessage: 'total'
    }"
    [offset]="paginaInicial"
    [externalPaging]="serverSidePagination"
    [count]="totalRows"
    (page)="setPage($event)"
  >
    <ngx-datatable-column
      [width]="50"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template
        ngx-datatable-cell-template
        let-value="value"
        let-isSelected="isSelected"
        let-row="row"
        let-onCheckboxChangeFn="onCheckboxChangeFn"
      >
        <input
          type="radio"
          [checked]="isSelected"
          (change)="onCheckboxChangeFn($event)"
          [disabled]="row.name == 'Claudine Neal'"
        />
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngFor="let tableHeader of tableHeaders"
      name="{{ tableHeader.name }}"
    >
      <ng-template ngx-datatable-header-template let-sort="sortFn">
        <span (click)="sort()">{{ tableHeader.label }}</span>
      </ng-template>

      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <p
          [ngClass]="{
            'text-blue':
              tableHeader.name === 'estado' && value == 'EN VIGENCIA',
            'text-red': tableHeader.name === 'estado' && value == 'CANCELADA',
            'text-yellow':
              tableHeader.name === 'estado' && value == 'FUERA VIGENCIA',
            'text-white':
              tableHeader.name === 'estado' &&
              value == 'EN VIGENCIA' &&
              selected[0]?.indice === row.indice
          }"
        >
          {{ value }}
        </p>
        <p
          *ngIf="!value"
          [ngClass]="{
            'text-blue':
              tableHeader.name === 'estado' && value == 'EN VIGENCIA',
            'text-red': tableHeader.name === 'estado' && value == 'CANCELADA',
            'text-yellow':
              tableHeader.name === 'estado' && value == 'FUERA VIGENCIA',
            'text-white':
              tableHeader.name === 'estado' &&
              value == 'EN VIGENCIA' &&
              selected[0]?.indice === row.indice
          }"
        >
          N/D
        </p>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>

<div *ngIf="noDataError" class="notFound">
  <div class="warning">
    <i class="fas fa-exclamation-triangle"></i>
    <h2 class="text-medium">No se han encontrado resultados</h2>
  </div>

  <p *ngIf="!msjError">
    Lo sentimos, no hemos encontrado ningún resultado con esta información.
    <br />
    Por favor, valida nuevamente tus datos o comunícate al teléfono
    <a [href]="'tel: 55 5723 7999'">55 5723 7999</a> , si lo <br />
    prefieres escríbenos al correo
    <a
      [href]="
        'mailto:' + customEmail
          ? customEmail
          : 'mxreembolso.beneficios@segurossura.com.mx'
      "
      >{{
        customEmail ? customEmail : "mxreembolso.beneficios@segurossura.com.mx"
      }}</a
    >
    <!-- siniestrosvida@segurossura.com.mx -->
    <!-- mxreembolso.beneficios@segurossura.com.mx -->
  </p>

  <p *ngIf="msjError">
    {{ msjError }}
  </p>
</div>

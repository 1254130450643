<div *ngIf="autoStart">
  <ngx-smart-modal
    #modalPrivacidad
    identifier="modalPrivacidad"
    class="text-center privacidad-modal safari_only"
    [customClass]="'nsm-centered'"
    [autostart]="autoStart"
    [closable]="true"
    [escapable]="false"
    [dismissable]="false"
    (onClose)="closing()"
  >
    <div class="typesLegal" style="text-align: justify">
      <h2 style="text-align: center">Aviso de Privacidad</h2>
      <p>
        “En cumplimiento a las disposiciones de la Ley Federal de Protección de
        Datos Personales en Posesión de los Particulares (LFPDPPP),&nbsp;<span
          class="bld"
          >Seguros SURA S.A. de C.V.</span
        >&nbsp;(antes Royal &amp; SunAlliance Seguros (México), S.A. de C.V.)
        también conocida como&nbsp;<span class="bld">Seguros SURA</span>, con
        domicilio en Boulevard Adolfo López Mateos No. 2448, Colonia Altavista,
        Delegación Álvaro Obregón, C.P. 01060., en la Ciudad de México, hace de
        su conocimiento que los datos personales que sean recabados y/o
        proporcionados a través de cuestionarios o formatos del seguro o a
        través de terceros autorizados por Seguros SURA o por vía electrónica,
        óptica, sonora, visual o por cualquier otro medio o tecnología, y
        aquellos generados con motivo de la relación jurídica que tengamos
        celebrada, o que en su caso, se celebre con Usted, serán tratados para
        las siguientes finalidades identificadas en los siguientes supuestos:
      </p>
      <p>&nbsp;</p>
      <p>
        <span class="bld"
          >Si Usted es nuestro Cliente, Proponente o Solicitante, Contratante,
          Asegurado, Beneficiario</span
        >, sus datos personales de identificación, patrimoniales, financieros,
        procesos legales en los que participe o haya participado, datos
        sensibles relativos a su estado de salud, preferencia sexual,
        características personales y características físicas serán tratados para
        evaluar y emitir sus solicitudes de seguro, dar trámite a sus
        reclamaciones de siniestros, cobrar las primas del seguro, mantener o
        renovar sus pólizas de seguro, para prevención de fraude y/o de
        operaciones ilícitas, para estudios estadísticos; así como para todos
        los fines relacionados con el cumplimiento de nuestras obligaciones de
        conformidad con lo establecido en la Ley Sobre el Contrato de Seguro y
        en la normatividad vigente.
      </p>
      <p>&nbsp;</p>
      <p>
        <span class="bld">Si Usted es nuestro Candidato o Empleado</span>, sus
        datos personales de identificación, patrimoniales, financieros,
        antecedentes laborales y académicos, procesos legales en los que
        participe o haya participado, datos sensibles relativos a su estado de
        salud, características personales y físicas serán tratados para todos
        los fines vinculados con la selección, reclutamiento, bolsa de trabajo
        interna, capacitación, desarrollo, pago de prestaciones laborales y para
        el cumplimiento de obligaciones fiscales y legales.
      </p>
      <p>&nbsp;</p>
      <p>
        <span class="bld"
          >Si Usted es nuestro Agente de Seguros, Promotor, Proveedor o
          Prestador de bienes y/o servicios</span
        >, sus datos personales de identificación, patrimoniales, financieros,
        antecedentes laborales y académicos, procesos legales en los que
        participe o haya participado, datos sensibles relativos a fotografías,
        identificación oficial, serán tratados para todos los fines vinculados
        con la relación jurídica contractual que tengamos celebrada con Usted.
      </p>
      <p>&nbsp;</p>
      <p>
        <span class="bld">Si Usted es nuestro visitante</span>, sus datos
        personales de identificación para su registro, y de características
        físicas que recabemos por medio de videograbaciones, serán tratados para
        permitirle el acceso a nuestras instalaciones.
      </p>
      <p>&nbsp;</p>
      <p>
        Por otra parte, en cualquiera de los supuestos anteriores, le informamos
        que sus datos personales podrán ser tratados para finalidades
        secundarias como son el ofrecimiento y promoción de bienes, productos y
        servicios y/o prospección comercial, y en el caso de recursos humanos
        para la bolsa de trabajo con otras empresas. Si Usted no desea recibir
        alguno de los ofrecimientos descritos en este párrafo, puede manifestar
        su negativa de la siguiente forma:
      </p>
      <p>&nbsp;</p>
      <p>
        a) Si proporciona sus datos en forma personal, siga los mecanismos y
        llenado del formato que se le proporcionará en el momento en el que haya
        recibido el presente aviso de privacidad.
      </p>
      <p>&nbsp;</p>
      <p>
        b) Si proporciona sus datos en forma directa, es decir a través de
        medios electrónicos, ópticos o sonoros, siga los mecanismos que se
        indican en nuestra página de Internet&nbsp;<a
          href="https://www.segurossura.com.mx/"
          target="_blank"
          rel="noopener"
          ><span style="word-wrap: break-word;" class="link">www.segurossura.com.mx.</span></a
        >
      </p>
      <p>&nbsp;</p>
      <p>
        c) Si proporciona sus datos en forma indirecta, es decir de un tercero
        calificado, siga los mecanismos que se indican en nuestra página de
        Internet&nbsp;<a
          href="https://www.segurossura.com.mx/"
          target="_blank"
          rel="noopener"
          ><span style="word-wrap: break-word;" class="link">www.segurossura.com.mx.</span></a
        >
      </p>
      <p>
        También hacemos de su conocimiento que sus datos podrán ser transferidos
        a:
      </p>
      <p>&nbsp;</p>
      <p>
        Terceros nacionales o extranjeros, con la finalidad de dar cumplimiento
        a nuestras obligaciones derivadas de Leyes a las que Seguros SURA está
        sujeta como Institución de Seguros.
      </p>
      <p>&nbsp;</p>
      <p>
        Para dar cumplimiento a nuestras obligaciones tributarias, así como para
        atender notificaciones, oficios o requerimientos oficiales de
        autoridades judiciales mexicanas y extranjeras.
      </p>
      <p>&nbsp;</p>
      <p>
        Al Instituto Mexicano del Seguro Social, con la finalidad de dar
        cumplimiento a obligaciones contenidas en la legislación de seguridad
        social.
      </p>
      <p>&nbsp;</p>
      <p>
        A Instituciones, Organizaciones o Entidades del Sector Asegurador para
        fines de selección de riesgos.
      </p>
      <p>&nbsp;</p>
      <p>
        De existir transferencias diferentes a las mencionadas en el apartado
        anterior y que requieran su consentimiento expreso, se lo informaremos.
      </p>
      <p>&nbsp;</p>
      <p>
        Usted podrá ejercer sus derechos ARCO, (Acceso, Rectificación,
        Cancelación, Oposición), de Revocación del consentimiento y limitación
        de uso de sus datos, mediante solicitud escrita a la dirección
        electrónica&nbsp;<a
          style="word-wrap: break-word;" class="link"
          href="mailto:oc.protecdatos@segurossura.com.mx"
          >oc.protecdatos@segurossura.com.mx</a
        >&nbsp;o bien directamente en nuestro domicilio en el Departamento de
        Protección de Datos Personales. Lo anterior está sujeto a que el
        ejercicio de dichos derechos no obstaculice el cumplimiento de alguna
        Ley vigente o mandato judicial así como para dar cumplimiento a las
        obligaciones derivadas de la relación jurídica entre Usted y Seguros
        SURA.
      </p>
      <p>&nbsp;</p>
      <p>
        En caso de solicitar el acceso a sus datos personales, Seguros SURA
        podrá cumplir con dicha obligación mediante cualquier medio físico, o
        electrónico.
      </p>
      <p>&nbsp;</p>
      <p class="bld">Cookies en nuestra página de Internet</p>
      <p>&nbsp;</p>
      <p>
        Cookie es una breve información que el portal de Internet envía a su
        computadora, la cual queda almacenada en el disco duro. Las siguientes
        ocasiones que ingrese a nuestro portal, podremos usar la información
        almacenada en la Cookie para facilitarte el uso de nuestro sitio de
        Internet. Por ejemplo, podemos usar su Cookie para almacenar una
        contraseña para que no tenga que ingresarla de nuevo cada vez que se
        traslade a una sección diferente de nuestro portal de Internet. Una
        Cookie no nos permite conocer su identidad personal a menos que
        expresamente elija proporcionárnosla. La mayoría de las Cookies expiran
        después de un periodo determinado de tiempo, o bien las puede borrar en
        el momento en que lo desee en su computadora. Asimismo, puede hacer que
        su navegador le avise cuando recibe una Cookie de manera que pueda
        aceptarla o rechazarla.
      </p>
      <p>&nbsp;</p>
      <p>
        Por favor, tome en cuenta que los mensajes enviados electrónicamente
        pueden ser manipulados o interceptados, por lo tanto Seguros SURA no se
        hace responsable si los mensajes llegan incompletos, retrasados, son
        eliminados o contienen algún programa malicioso (virus informático).
      </p>
      <p>&nbsp;</p>
      <p>
        Para cualquier asunto relacionado con este Aviso de Privacidad y sobre
        el tratamiento de sus datos personales, puede contactarnos en el
        Departamento de Datos Personales que se ubica en nuestro domicilio.
      </p>
      <p>&nbsp;</p>
      <p>
        El presente Aviso de Privacidad, así como sus modificaciones, estarán a
        su disposición en la página de Internet&nbsp;<a
          style="word-wrap: break-word;" class="link"
          href="https://www.segurossura.com.mx/"
          target="_blank"
          rel="noopener"
          >www.segurossura.com.mx</a
        >&nbsp;y colocados en nuestras oficinas y sucursales.
      </p>
      <p>&nbsp;</p>
      <hr />
      <p>&nbsp;</p>
      <p>Fecha de última modificación: Septiembre 2016.</p>
    </div>

    <footer>
      <div class="accept">
        <p>
          <input
            type="checkbox"
            name="checkbox1"
            id="checkbox1"
            class="secondary"
            [(ngModel)]="accepted"
          /><label for="checkbox1" class="text-secondary text-bold"
            >Acepto los términos de privacidad
          </label>
        </p>
      </div>
      <div class="continue">
        <button
          [disabled]="!accepted"
          class="btn btn-primary"
          (click)="closeModal()"
        >
          Continuar
        </button>
      </div>
    </footer>
  </ngx-smart-modal>
</div>

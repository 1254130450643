import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-table-card-actions',
  templateUrl: './table-card-actions.component.html',
  styleUrls: ['./table-card-actions.component.scss']
})
export class TableCardActionsComponent implements OnInit {
  @Input() tableHeaders: any;
  @Input() headerKey: string;
  @Input() stateType: string;
  @Input() rows: any;
  @Input() name: any;
  @Input() title: any;
  @Input() actions: Array<any>;
  @Input() actionColumns: Array<any>;
  @Output() action: EventEmitter<any>;

  constructor() {
    this.action = new EventEmitter();
  }

  ngOnInit(): void {
  }

  actionEmit(name: string, value: any) {
    this.action.emit({name: name, data: value});
  }

  defaultAction(value) {
    if (this.actions) {
      this.action.emit({name: this.actions[0].action, data: this.actions[0].key ? value[this.actions[0].key] : value});
    }
  }

}

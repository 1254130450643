<div class="table-print">
  <ngx-datatable
    class="material basic"
    [rows]="_rows"
    [headerHeight]="50"
    rowHeight="auto"
    [limit]="limit"
  >
    <!-- <ngx-datatable-column
      *ngFor="let tableHeader of tableHeaders"
      name="{{ tableHeader.name }}"
    >
      <ng-template ngx-datatable-header-template let-sort="sortFn">
        <span (click)="sort()">{{ tableHeader.label }}</span>
      </ng-template>
    </ngx-datatable-column> -->

    <ngx-datatable-column
      name="Concepto"
      [width]="100"
      [sortable]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.concepto }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="Reclamado"
      [width]="100"
      [sortable]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.reclamado }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="Autorizado"
      [width]="100"
      [sortable]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.autorizado }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="Gastos no cubiertos"
      [width]="100"
      [sortable]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.gastos }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Deducible aplicado"
      [width]="100"
      [sortable]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.deducible }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Coaseguro aplicado"
      [width]="100"
      [sortable]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.coaseguro }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Pagado"
      [width]="100"
      [sortable]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.pagado }}
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>

<div class="action-card-container">
  <div class="header-card">
    <div *ngIf="!isAsegurado" class="name-card text-bold text-medium">
      {{ beneficiario.nombre_uno }}
      {{ beneficiario.apellido_uno ? beneficiario.apellido_uno : "" }}
    </div>
    <div *ngIf="isAsegurado" class="name-card text-bold text-medium">
      {{ beneficiario.nombre_uno }}
      {{ beneficiario.apellido_uno ? beneficiario.apellido_uno : "" }}
    </div>
    <div *ngIf="!isAsegurado" class="action-button">
      <button class="btn-action btn-success" (click)="editar(beneficiarioId)">
        <i class="fas fa-pen"></i>
      </button>
      <button class="btn-action btn-danger" (click)="eliminar(beneficiarioId)">
        <i class="fas fa-trash"></i>
      </button>
    </div>
    <div class="action-button-responsive">
      <button class="btn-action btn-ellipsis" (click)="activeOverlay()">
        <i class="fas fa-ellipsis-h"></i>
      </button>
    </div>
  </div>
  <div class="card-content">
    <div class="card-info">
      <div class="data">
        <p *ngIf="beneficiario.rfc">{{ beneficiario.rfc }}</p>
        <p *ngIf="!beneficiario.rfc">------</p>
        <span>{{ headers[0] }}</span>
      </div>
      <div class="data">
        <p *ngIf="beneficiario.correoElectronico">
          {{ beneficiario.correoElectronico }}
        </p>
        <p *ngIf="!beneficiario.correoElectronico">------</p>
        <span>{{ headers[1] }}</span>
      </div>
      <div class="data hide">
        <p *ngIf="beneficiario.tipoPago">{{ beneficiario.tipoPago }}</p>
        <p *ngIf="!beneficiario.tipoPago">------</p>
        <span>{{ headers[2] }}</span>
      </div>
      <div class="data hide">
        <p *ngIf="beneficiario.fecha_nacimiento">
          {{
            !beneficiario.fecha_nacimiento.includes("-")
              ? beneficiario.fecha_nacimiento
              : (beneficiario.fecha_nacimiento | date: "dd/MM/yyyy")
          }}
        </p>
        <p *ngIf="!beneficiario.fecha_nacimiento">------</p>
        <span>{{ headers[3] }}</span>
      </div>
    </div>
    <div class="percentage-info" style="display: none">
      <app-circle-percentage
        [percentage]="porcentaje"
        title="{{ porcentaje }}"
      ></app-circle-percentage>
    </div>
  </div>

  <div class="overlay-actions" [ngClass]="status ? 'show' : 'hide'">
    <div
      style="text-align: right; padding: 10px"
      class="text-secondary"
      (click)="activeOverlay()"
    >
      <i class="fas fa-times close-btn"></i>
    </div>
    <div *ngIf="!isAsegurado" class="buttons">
      <button class="btn-action btn-success" (click)="editar(beneficiarioId)">
        <i class="fas fa-pen"></i>
        <span>Editar</span>
      </button>
      <button class="btn-action btn-danger" (click)="eliminar(beneficiarioId)">
        <i class="fas fa-trash"></i>
        <span>Eliminar</span>
      </button>
    </div>
  </div>
</div>

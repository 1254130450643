import { de } from 'date-fns/locale';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {

  @Input() arrayStep:any []
  @Input() active:string

  // arrayStep:any [] = []


  constructor() { 

    // this.arrayStep = [
    //   {
    //     nombre:'Identificación General',
    //     actual:true
    //   },
    //   {
    //     nombre:'Configuración de reglas',
    //     actual:false
    //   },
    //   {
    //     nombre:'Asociación Dinámica',
    //     actual:false
    //   },
    //   {
    //     nombre:'Acciones y Procedimiento',
    //     actual:false
    //   },
    // ]

  }

  ngOnInit(): void {
    

  }

}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'tabs',
  templateUrl: './tabs-container.component.html',
  styleUrls: ['./tabs-container.component.scss'],
})
export class TabsContainerComponent {
  @Input() arrayTabs: any;
  @Input() tam: number;
  @Input() valor: number;

  selectTab(tab: any) {
    if (tab.disabled) return;

    this.arrayTabs.forEach((tab) => {
      tab.active = false;
    });
    tab.active = true;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FileSystemDirectoryEntry,
  FileSystemFileEntry,
  NgxFileDropEntry
} from 'ngx-file-drop';

@Component({
  selector: 'app-drag-ndrop-docs',
  templateUrl: './drag-ndrop-docs.component.html',
  styleUrls: ['./drag-ndrop-docs.component.scss']
})
export class DragNdropDocsComponent implements OnInit {
  //load, load-download, loaded
  @Input() type: any;
  @Input() title: any;
  @Input() required: any;
  @Output() loaded = new EventEmitter<any>();
  // @Input() file: any;

  @Input() info: any;
  @Input() format: any;

  file: any = {
    name: '',
    size: ''
  };

  public files: NgxFileDropEntry[] = [];

  constructor() {
    this.type = 'load';
  }

  ngOnInit(): void {}

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;

    this.loaded.emit(this.title);

    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file
          // console.log(droppedFile.relativePath, file);

          var sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
          this.file.name = file.name;
          this.file.size = sizeInMB;

          /**
         // You could upload it like this:
         const formData = new FormData()
         formData.append('logo', file, relativePath)

         // Headers
         const headers = new HttpHeaders({
           'security-token': 'mytoken'
         })

         this.http.post('https://mybackend.com/api/upload/sanitize-and-save-logo', formData, { headers: headers, responseType: 'blob' })
         .subscribe(data => {
           // Sanitized logo returned from backend
         })
         **/
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  fileLeave(e: any) {
    // console.log(e);
  }

  saveFile() {
    // this.download('test.txt', 'Hello world!');
    this.download(this.file.name, this.file);
  }

  download(filename: any, text: any) {
    var pom = document.createElement('a');
    pom.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
    );
    pom.setAttribute('download', filename);

    if (document.createEvent) {
      var event = document.createEvent('MouseEvents');
      event.initEvent('click', true, true);
      pom.dispatchEvent(event);
    } else {
      pom.click();
    }
  }
}

import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-table-facturas',
  templateUrl: './table-facturas.component.html',
  styleUrls: ['./table-facturas.component.scss'],
})
export class TableFacturasComponent implements OnInit {
  @Input() title: any;
  @Input() tableHeaders: any;
  @Input() rows: any;
  @Input() type: any;
  @Input() stateType: any;
  @Input() actionColumns: Array<any> = [];
  @Input() actions: Array<any> = [];
  _rows: any;
  @Output() action: EventEmitter<any>;
  @Output() setPageEvent: EventEmitter<any>;
  @Input() noDataError: any;
  @Input() unlimited;

  temp: Array<any> = [];
  selected: Array<any> = [];

  entries: Array<any> = [];

  ColumnMode = ColumnMode;
  SelectionType = SelectionType;

  initRows;
  initRowsFlag = true;
  limit = 10;
  @Input() serverSidePagination: boolean;
  @Input() totalRows;
  editing = {};

  constructor(private datePipe: DatePipe) {
    this.entries = [5, 10, 15];
    this.selected = [];
    this.action = new EventEmitter();
    this.setPageEvent = new EventEmitter();
  }

  ngOnInit(): void {
    if (this.unlimited) this.limit = 10000;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rows) {
      this._rows = changes.rows.currentValue;
      this.temp = [...this._rows];

      // this.transformDates(this._rows);
      // this.transformDates(this.temp);
    }
  }

  onSelect({ selected }: any) {
    console.log('Select Event', selected, this.selected);
  }

  onActivate(event: any) {
    // console.log('Activate Event', event);
    if (event.type == 'click') {
      this.action.emit({ row: event.row });
    }
  }

  actionEmit(name: string, value: any) {
    this.action.emit({ name: name, data: value });
  }

  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();

    let keys = [];
    for (let index = 0; index < this.tableHeaders.length; index++) {
      const element = this.tableHeaders[index].name;
      keys.push(element);
    }

    const temp = this.temp.filter(function (item) {
      for (let i = 0; i < keys.length; i++) {
        if (
          item[keys[i]].toString().toLowerCase().indexOf(val) !== -1 ||
          !val
        ) {
          return true;
        }
      }
    });

    this.rows = temp;
  }

  switchCheckbox(id: any) {
    let currentID = '';
    for (let index = 0; index < this.rows.length; index++) {
      currentID = 'card-' + index;
      document.getElementById(currentID)?.classList.remove('active');
    }
    document.getElementById('radio-' + id)?.click();
    document.getElementById('card-' + id)?.classList.add('active');
  }

  checkSelectable(event: any) {
    // console.log('Checking if selectable', event);
    return event.name !== 'Claudine Neal';
  }

  ngDoCheck(): void {
    if (this.rows.length > 0) {
      this._rows = [...this.rows];
      this.transformDates(this._rows);
      this.transformDates(this.temp);
    }
  }

  transformDates(rows) {
    const regexYYYYmmDD = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/;

    for (let index = 0; index < rows.length; index++) {
      let element = rows[index];

      for (let key in element) {
        const item = element[key];

        if (item && item.toString().match(regexYYYYmmDD)) {
          element[key] = this.datePipe.transform(item, 'dd/MM/yyyy');
        }
      }
    }
  }

  setPage(e) {
    this.setPageEvent.emit(e);
    if (this.initRowsFlag) {
      this.initRows = [...this.rows];
      this.initRowsFlag = false;
    }

    if (this.serverSidePagination) {
      this.rows = this.initRows.slice(
        e.offset * e.pageSize,
        (e.offset + 1) * e.pageSize
      );
    }
  }

  updateValue(event, cell, rowIndex) {
    // console.log('inline editing rowIndex', rowIndex);
    this.editing[rowIndex + '-' + cell] = false;
    this.rows[rowIndex][cell] = event.target.value;
    this.rows = [...this.rows];
    // console.log('UPDATED!', this.rows[rowIndex][cell]);
  }
}

import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-preview-pdf',
  templateUrl: './preview-pdf.component.html',
  styleUrls: ['./preview-pdf.component.scss'],
})
export class PreviewPdfComponent implements OnInit {
  iframeURL;
  docURL;
  loading = true;

  constructor(public _route: ActivatedRoute, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    // localStorage.setItem(
    //   'docURL',
    //   'https://bienestar.blob.core.windows.net/proveedor//309/documentos/carta_rechazo%20%2811%29.pdf'
    // );

    this.docURL = localStorage.getItem('previewpdf');

    let aux = `https://docs.google.com/viewerng/viewer?url=${this.docURL}&embedded=true`;

    this.iframeURL = this.sanitizer.bypassSecurityTrustResourceUrl(aux);

    var count = 0;
    let iframe = document.getElementById('iframe');

    iframe.addEventListener('load', () => {
      count++;
      if (count > 0) {
        clearInterval(interval);
        this.loading = false;
        iframe.style.left = '0px';
        iframe.style.height = '100%';
        console.log('cargado');
      }
    });

    var interval = setInterval(() => {
      this.ngOnInit();
    }, 2000);
  }
}

<ngx-datatable
  class="material basic"
  [rows]="_rows"
  [headerHeight]="50"
  [footerHeight]="50"
  rowHeight="auto"
  [limit]="5"
  [offset]="0"
>
  <ngx-datatable-column
    name="CPT"
    [width]="80"
    [sortable]="true"
    [draggable]="false"
    [resizeable]="false"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.cpt }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    name="Procedimiento"
    [width]="200"
    [sortable]="true"
    [draggable]="false"
    [resizeable]="false"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.procedimiento }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Cantidad a autorizar">
    <ng-template
      ngx-datatable-cell-template
      let-rowIndex="rowIndex"
      let-value="value"
      let-row="row"
    >
      <input
        autofocus
        (blur)="updateValue($event, 'cantidad', rowIndex)"
        type="text"
        [value]="row.cantidad"
        [disabled]="row.pct"
        onkeypress="return ( event.keyCode != 32 && ( (event.keyCode >= 48) && (event.keyCode <=57) ))"
      />
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="% de aprobación">
    <ng-template
      ngx-datatable-cell-template
      let-rowIndex="rowIndex"
      let-value="value"
      let-row="row"
    >
      <input
        autofocus
        (blur)="updateValue($event, 'pct', rowIndex)"
        type="text"
        [value]="row.pct"
        [disabled]="row.cantidad"
        onkeypress="return ( event.keyCode != 32 && ( (event.keyCode >= 48) && (event.keyCode <=57) ))"
      />
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>

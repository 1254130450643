import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-circle-percentage',
  templateUrl: './circle-percentage.component.html',
  styleUrls: ['./circle-percentage.component.scss']
})
export class CirclePercentageComponent implements OnInit {
  @Input() percentage: number;
  @Input() title: string;
  constructor() {
    this.percentage = 20;
    this.title = this.percentage + '%';
  }

  ngOnInit(): void {
    console.log(this.percentage);

    this.title = this.percentage + '%';
  }
}

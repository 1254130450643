import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss'],
})
export class InputFileComponent implements OnInit {
  fileName: any;
  file: any;
  @Output() fileEmitter = new EventEmitter<any>();
  @Input() fileExist: any;
  fileBandera: boolean;
  constructor() {
    this.fileBandera = false;
  }

  ngOnInit(): void {}

  open(uploader) {
    uploader.click();
  }

  fileChangeEvent(e: any) {
    this.file = e[0];
    this.fileEmitter.emit(this.file);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fileExist) {
      this.file = null;
    }
  }
}

<div class="action-card-container" *ngIf="type != 'add'">
  <div class="header-card">
    {{ product?.detalle.producto_poliza }}
  </div>
  <div class="card-content">
    <div class="info">
      <div class="left">
        <div class="poliza">
          # Póliza:
          <span class="text-bold">{{ product?.poliza }}</span>
        </div>
        <div class="asegurado">{{ product?.detalle.titular }}</div>
      </div>
      <div
        class="right"
        [ngClass]="{
          'status-danger': product?.detalle.vigencia_estado === 'CANCELADA',
          'status-ok': product?.detalle.vigencia_estado === 'XXX'
        }"
      >
        <div style="text-align: center;">
          <i class="fas fa-check-circle"></i>
          <div class="status">{{ product?.detalle.vigencia_estado }}</div>
        </div>
      </div>
    </div>

    <div class="expires">
      <div class="top">
        <p>Días restantes</p>
        <p>
          <span>{{ product?.detalle.dias_restantes }}</span> días
        </p>
      </div>
      <div id="myProgress">
        <div id="myBar" [style]="pct"></div>
      </div>
      <div class="bottom">
        Vence
        <span>{{ product?.detalle.vigencia_fin | date: "dd/MM/yyyy" }}</span>
      </div>
    </div>
  </div>
  <div class="footer-card">
    <div class="name-card">
      VER DETALLE
    </div>
  </div>
</div>

<div class="action-card-container add" *ngIf="type == 'add'">
  <div class="header-card" style="visibility: hidden;">
    Ejemplo de Nombre del Producto
  </div>
  <div class="card-content" style="visibility: hidden;">
    <div class="info">
      <div class="left">
        <div class="poliza">
          # Póliza: <span class="text-bold">2139124</span>
        </div>
        <div class="asegurado">NOMBRE DE ASEGURADO</div>
      </div>
      <div class="right">
        <div style="text-align: center;">
          <i class="fas fa-check-circle"></i>
          <div class="status">VIGENTE</div>
        </div>
      </div>
    </div>

    <div class="expires" style="visibility: hidden;">
      <div class="top">
        <p>Días restantes</p>
        <p>200 días</p>
      </div>
      <div id="myProgress">
        <div id="myBar"></div>
      </div>
      <div class="bottom">Vence dd/mm/yy</div>
    </div>
  </div>
  <div class="footer-card" style="visibility: hidden;">
    <div class="name-card">
      VER DETALLE
    </div>
  </div>

  <div class="centered">
    <i class="fas fa-plus-circle"></i>
    <p>Agregar nuevo producto SURA</p>
  </div>
</div>

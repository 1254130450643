<ngx-file-drop
  dropZoneLabel="Drop files here"
  (onFileDrop)="dropped($event)"
  (onFileLeave)="fileLeave($event)"
  class="dnd-factura"
>
  <ng-template
    ngx-file-drop-content-tmp
    let-openFileSelector="openFileSelector"
  >
    <div class="center-container">
      <div class="buttons">
        <div class="action-button" (click)="openFileSelector()">
          <i class="fas fa-paperclip"></i>
          <p class="info">
            Por favor adjunte todas las facturas que desea solicitar en este
            reembolso
          </p>
          <p class="text-bold">FORMATO XML</p>
        </div>
      </div>
      <div class="title">
        {{ title }}
      </div>
    </div>
  </ng-template>
</ngx-file-drop>

<div class="msg-transferencia">
  <p>
    NOTA: las facturas marcadas en rojo no se han podido validar en el SAT y
    probablemente no puedan ser pagadas
  </p>
</div>

<div class="filesContainer">
  <div class="file" *ngFor="let file of arrayFiles; let i = index">
    <i class="fas fa-check-circle"></i>
    <span>{{ file }}</span>
    <i (click)="delete(i)" class="fas fa-trash-alt"></i>
  </div>
</div>

import { DatePipe } from '@angular/common';
import {
  Component,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { getFormattedDate } from 'src/functions/common-functions';

@Component({
  selector: 'app-table-singleselect',
  templateUrl: './table-singleselect.component.html',
  styleUrls: ['./table-singleselect.component.scss'],
})
export class TableSingleselectComponent {
  @Input() tableHeaders: any;
  @Input() isSearching: any;
  @Input() name: any;
  @Input() noDataError: any;
  @Input() msjError: any;
  @Input() customEmail: string;
  _rows: any;
  _setSelected: any;

  initRows;
  initRowsFlag = true;
  limit = 10;
  @Input() serverSidePagination: boolean;
  @Input() totalRows;
  @Input() paginaInicial;

  @Output() setPageEvent: EventEmitter<any>;

  @Input()
  set rows(val: any) {
    if (val != undefined) {
      this._rows = val;
      this.temp = val;

      this.transformDates(this._rows);
      this.transformDates(this.temp);
    }
  }

  @Input()
  set setSelected(val: any) {
    if (val != undefined && val != null) {
      this._setSelected = val;
      this.selected = [this._rows[val]];
    }
  }

  @Output() actionSelected = new EventEmitter<any>();

  firstLoad: boolean = false;

  temp: Array<any> = [];
  selected: Array<any> = [];

  entriesVal: any;
  entries: Array<any> = [];

  ColumnMode = ColumnMode;
  SelectionType = SelectionType;

  constructor(private datePipe: DatePipe) {
    this.entries = [5, 10, 15];
    this.entriesVal = 5;
    this.selected = [];
    this.setPageEvent = new EventEmitter();
  }

  ngOnInit(): void {
    console.log('Data Error:', this.noDataError);

    if (this._setSelected != undefined && this._setSelected != null) {
      this.selected = [this._rows[this._setSelected]];
    }

    if (!this.paginaInicial) this.paginaInicial = 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isSearching) {
      this.resetTable();
    }

    if (changes.setSelected) {
      let indexAux = this._rows.findIndex(
        (x) => x.indice === this._setSelected
      );

      if (this._setSelected != undefined && this._setSelected != null) {
        this.selected = [this._rows[indexAux]];
      }
    }
  }

  onSelect({ selected }: any) {
    // console.log('Select Event', selected, this.selected);

    let tempSelected = JSON.parse(JSON.stringify(this.selected));
    this.transformDatesReverse(tempSelected);
    this.actionSelected.emit(tempSelected);
  }

  onActivate(event: any) {
    // console.log('Activate Event', event);
  }

  checkSelectable(event: any) {
    console.log('Checking if selectable', event);
    return event.name !== 'Claudine Neal';
  }

  resetTable() {
    this.selected = [];
    // this._setSelected = null;
  }

  transformDates(rows) {
    const regexYYYYmmDD = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/;

    for (let index = 0; index < rows.length; index++) {
      let element = rows[index];

      for (let key in element) {
        const item = element[key];

        if (item && item.toString().match(regexYYYYmmDD)) {
          element[key] = this.datePipe.transform(item, 'dd/MM/yyyy');
        }
      }
    }
  }

  transformDatesReverse(rows) {
    const regexDDmmYYYY =
      /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

    for (let index = 0; index < rows.length; index++) {
      let element = rows[index];

      for (let key in element) {
        const item = element[key];

        if (item && item.toString().match(regexDDmmYYYY)) {
          element[key] = getFormattedDate(item, 'yyyy-mm-dd');
        }
      }
    }
  }

  setPage(e) {
    this.setPageEvent.emit(e);

    if (this.initRowsFlag) {
      this.initRows = [...this._rows];
      this.initRowsFlag = false;
    }

    if (this.serverSidePagination) {
      this.rows = this.initRows.slice(
        e.offset * e.pageSize,
        (e.offset + 1) * e.pageSize
      );
    }
  }
}

import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-steps-bar',
  templateUrl: './steps-bar.component.html',
  styleUrls: ['./steps-bar.component.scss'],
})
export class StepsBarComponent implements OnInit {
  @Input() cantSteps: any;
  @Input() actualStep: any;
  public stepsArray: Array<any>;
  public percentage: any;

  constructor() {}

  ngOnInit(): void {
    this.stepsArray = [];
    for (let index = 0; index < this.cantSteps; index++) {
      this.stepsArray.push(0);
    }

    this.percentage = (
      ((this.actualStep - 1) / this.cantSteps) *
      100
    ).toFixed();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.stepsArray = [];
    for (let index = 0; index < this.cantSteps; index++) {
      this.stepsArray.push(0);
    }

    this.percentage = (
      ((this.actualStep - 1) / this.cantSteps) *
      100
    ).toFixed();
  }
}

import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ErrorComponent } from './error/error/error.component';

const routes: Routes = [
  {
    path: 'gestion-proveedor',
    loadChildren: () =>
      import('./gestion-proveedor/gestion-proveedor.module').then(
        (m) => m.GestionProveedorModule
      ),
  },
  {
    path: 'dashboard',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
    ],
  },

  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path:'error',
    component: ErrorComponent
  }

  // {
  //   path: '**',
  //   redirectTo: 'home',
  //   pathMatch: 'full',
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

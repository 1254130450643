import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() text: string;
  @Input() class: string;
  @Input() iconLeft: string;
  @Input() iconRight: string;
  constructor() { 
    this.text="";
    this.class="";
    this.iconLeft="";
    this.iconRight="";
  }
  ngOnInit(): void {
  }
}

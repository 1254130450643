<div class="action-card-container" *ngIf="type != 'add'">
  <div class="header-card">
    {{ descProducto }}
  </div>
  <div class="card-content">
    <div class="info">
      <div class="asegurado">{{ poliza?.asegurado }}</div>
      <div class="row">
        <div class="item">
          Oficina: <span>{{ poliza?.oficina }}</span>
        </div>
        <div class="item">
          Ramo: <span>{{ poliza?.ramo }}</span>
        </div>
      </div>
      <div class="row">
        <div class="item">
          No. Póliza: <span>{{ poliza?.poliza }}</span>
        </div>
        <div class="item">
          Certificado: <span>{{ poliza?.certificado }}</span>
        </div>
      </div>
    </div>

    <div class="expires">
      <div class="top">
        <p>Días restantes</p>
        <p>
          <span>{{ poliza?.diasRestantes }}</span> días
        </p>
      </div>
      <div id="myProgress">
        <div id="myBar" [style]="pct"></div>
      </div>
      <div class="bottom">
        Vence
        <span>{{ poliza?.vigenciaFin | date: "dd/MM/yyyy" }}</span>
      </div>
    </div>
  </div>
  <div class="footer-card" (click)="verDetalle()">
    <div class="name-card">VER DETALLE</div>
  </div>
</div>

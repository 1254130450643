<div *ngIf="tam == 1">
  <div class="tabsBar">
    <div
      class="tab-solo"
      *ngFor="let tab of arrayTabs"
      (click)="selectTab(tab)"
      [ngClass]="{ active: tab.active }"
    >
      {{ tab.tabTitle }}
    </div>
  </div>
</div>

<div *ngIf="tam != 1">
  <div class="tabsBar">
    <div
      class="tab"
      *ngFor="let tab of arrayTabs"
      (click)="selectTab(tab)"
      [ngClass]="{ active: tab.active, disabled: tab.disabled }"
    >
      <span> {{ tab.tabTitle }}</span>

      <div *ngIf="tab.valor" class="position-count">
        <div class="section-count">
          <p class="text-bold">{{ tab.valor }}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-content></ng-content>

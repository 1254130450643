import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-container-message',
  templateUrl: './container-message.component.html',
  styleUrls: ['./container-message.component.scss']
})
export class ContainerMessageComponent {
  @Input() messageText: string = '';
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  closeContainerMessage() {
    this.close.emit();
  }
}

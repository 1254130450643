import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-table-card-basic-actions',
  templateUrl: './table-card-basic-actions.component.html',
  styleUrls: ['./table-card-basic-actions.component.scss'],
})
export class TableCardBasicActionsComponent implements OnInit {
  @Input() tableHeaders: any;
  @Input() rows: any;
  @Input() name: any;
  @Input() title: any;
  @Input() type: any;
  p: any;
  @Input() isSearching: any;
  @Input() noDataError: any;

  @Input() actionColumn: any;

  @Output() action = new EventEmitter();
  @Output() eventActionColumn = new EventEmitter();

  hasManyActions = false;

  @Input() serverSidePagination: boolean;
  @Input() totalRows;
  @Output() setPageEvent = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    if (this.actionColumn.length > 1) this.hasManyActions = true;
    else this.hasManyActions = false;
  }

  clicked(i) {
    if (!this.hasManyActions) {
      const index = (this.p - 1) * 5 + i;
      if (this.type === 'tramites' || this.type === 'agente') {
        this.action.emit({
          name: 'detail',
          data: this.rows[index],
        });
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isSearching) {
      this.p = 1;
    }
  }

  openOverlay(card) {
    card.classList.remove('hide');
    card.classList.add('show');
  }

  closeOverlay(card) {
    card.classList.remove('show');
    card.classList.add('hide');
  }

  emitActionColumn(col) {
    this.eventActionColumn.emit(col);
  }

  setPage(e) {
    this.p = e;
    this.setPageEvent.emit({ offset: e - 1 });
  }
}

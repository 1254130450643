<div *ngIf="!noDataError">
  <div
    *ngFor="
      let row of rows
        | paginate
          : {
              itemsPerPage: 10,
              currentPage: p,
              totalItems: totalRows
            };
      let i = index
    "
  >
    <div
      #card
      class="card"
      style="margin: 10px 0px"
      id="{{ 'card-' + name + i }}"
      (click)="selectCard(i)"
    >
      <input
        class="card-selector"
        type="radio"
        name="{{ name }}"
        id="{{ 'radio-' + name + i }}"
      /><label for="{{ 'radio-' + i }}"></label>
      <div class="card-body">
        <div *ngFor="let col of tableHeaders; let j = index" class="col">
          <div class="col1" style="padding-right: 30px">
            <p class="cell-content">{{ row[col.name] }}</p>
            <p>{{ col.label }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div style="text-align: center" *ngIf="!serverSidePagination">
    <pagination-controls
      class="my-pagination"
      (pageChange)="p = $event"
      previousLabel="Anterior"
      nextLabel="Siguiente"
    ></pagination-controls>
  </div>

  <div style="text-align: center" *ngIf="serverSidePagination">
    <pagination-controls
      class="my-pagination"
      (pageChange)="setPage($event)"
      previousLabel="Anterior"
      nextLabel="Siguiente"
    ></pagination-controls>
  </div>
</div>

<div
  *ngIf="noDataError"
  class="flex-container"
  class="flex-container bg-danger text-danger pr-5 pl-5 mt-3"
>
  <div style="margin-left: -10px">
    <i class="fas fa-exclamation-circle"></i>
    <label style="margin: 0 10px"
      >No se han obtenido resultados de esta búsqueda</label
    >
  </div>
</div>

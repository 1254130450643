<div class="container">
  <div class="section-stepper" *ngFor="let step of arrayStep; let i = index">
    <div
      class="step"
      [ngClass]="{
        active: step.label == active,
        touched: step.touched == true
      }"
    >
      <p class="text-medium text-bold">{{ i + 1 }}</p>
      <div *ngIf="i > 0" class="border-step"></div>
    </div>
    <div class="text-center text-xsmall p-1">
      <p *ngIf="step.label == active">{{ step.label }}</p>
    </div>
  </div>
</div>

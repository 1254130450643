import { environment } from 'src/environments/environment';

export class AppConstants {
  public static get apiBienestar(): string {
    return environment.apiBienestar;
  }

  public static get bienestarAuth(): string {
    return environment.bienestarAuth;
  }

  public static get bienestarURL(): string {
    return environment.bienestarURL;
  }

  public static get loginURL(): string {
    return environment.loginURL;
  }

  public static get loginURLAlianza(): string {
    return environment.loginURLAlianza;
  }

  public static get alianzasURL(): string {
    return environment.alianzasURL;
  }

  public static keyCapchat = environment.keyCapchat;
}

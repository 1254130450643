<div class="container-relacion mt-4">
  <div class="row">
    <p>Relación a Validar</p>
    <div class="actions">
      <div
        class="text-primary"
        [ngClass]="{
          disable:
            count == 0 || errorRepeat || previewDisabled === 'true' || someVoid
        }"
      >
        <i (click)="configRela()" class="fas fa-cogs"></i>
      </div>
      <div
        class="text-danger"
        [ngClass]="{ disable: previewDisabled === 'true' }"
      >
        <i (click)="removeRenglon()" class="fas fa-trash"></i>
      </div>
    </div>
  </div>

  <div class="section-catalogos mt-3">
    <form [formGroup]="form">
      <div formArrayName="catalogos" style="display: flex; flex-wrap: wrap">
        <div *ngFor="let item of catalogo.controls; let i = index">
          <div class="mr-2" [formGroupName]="i">
            <label *ngIf="catalogo.controls.length == 1"
              >Catálogo {{ i + 1 }}</label
            >
            <label *ngIf="catalogo.controls.length != 1"
              >Catálogo {{ i + 1 }}(*)</label
            >
            <select
              class="form-control"
              (change)="detectedCatalogo($event.target.value)"
              formControlName="catalogo"
            >
              <option value="">Seleccione</option>
              <option *ngFor="let cat of arrayCatalogo" [value]="cat.code">
                {{ cat.desc }}
              </option>
            </select>
          </div>
        </div>
        <div
          class="text-primary add-catalogos"
          [ngClass]="{
            disable: disableButton == true || arrayCatalogo.length == 0
          }"
        >
          <i (click)="addCatalogo()" class="fas fa-plus-circle"></i>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="errorRepeat">
    <small class="text-danger mr-2">{{ errorRepeat }}</small>
  </div>
</div>

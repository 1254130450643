<div class="container-box">
  <form [formGroup]="form">
    <div class="row">
      <div [ngClass]="{ disabled: !form.valid, sectionIcon: form.valid }">
        <div class="form-group">
          <i class="fas fa-check-circle"></i>
        </div>
      </div>

      <div class="form-group">
        <p class="text-bold">
          Factura {{ factura.id }} | RFC Emisor: {{ factura.rfcEmisor }} -
          Monto: $ {{ factura.total | number: ".2":"es" }}
        </p>
      </div>

      <div class="section-datos">
        <div class="form-group w-70">
          <label>Tipo de atención (*)</label>
          <div>
            <select
              class="form-control"
              formControlName="atencion"
              required
              (ngModelChange)="selectChange()"
            >
              <option
                *ngFor="let item of arrayAtenciones; let i = index"
                [value]="item.nombre"
              >
                {{ item.nombre }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AuthService } from 'projects/auth-app/src/app/auth/auth.service';

@Component({
  selector: 'app-aviso-privacidad',
  templateUrl: './aviso-privacidad.component.html',
  styleUrls: ['./aviso-privacidad.component.scss']
})
export class AvisoPrivacidadComponent implements OnInit {
  accepted: boolean = false;
  @Input() appToGo: any;
  @Input() autoStart: boolean;
  @Output() action = new EventEmitter<any>();

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private _AuthService: AuthService
  ) {}

  ngOnInit(): void {
    console.log(this.autoStart);
  }

  closeModal() {
    this.ngxSmartModalService.getModal('modalPrivacidad').close();
    if (this.appToGo == 'bienestar') {
      this._AuthService
        .aceptarPrivacidad(this._AuthService.getToken())
        .subscribe(response => {
          console.log(response);
        });
      let url = localStorage.getItem('appURL');

      window.location.href = url;
    }
  }

  closing() {
    this.action.emit('closing');
  }
}

<div *ngIf="!noDataError">
  <div class="table-top-bar basic">
    <div class="search-container mb-3" *ngIf="title">
      <label class="mb-4 text-bold-medium">{{ title }}</label>
    </div>
  </div>
  <ngx-datatable
    class="material basic"
    [rows]="_rows"
    [columnMode]="ColumnMode.force"
    [headerHeight]="50"
    [footerHeight]="50"
    rowHeight="auto"
    [selected]="selected"
    (select)="onSelect($event)"
    [selectCheck]="checkSelectable"
    [limit]="limit"
    [offset]="0"
    [externalPaging]="serverSidePagination"
    [count]="totalRows"
    (page)="setPage($event)"
    [scrollbarH]="scrollbarH"
  >
    <ngx-datatable-column
      *ngFor="let tableHeader of tableHeaders"
      name="{{ tableHeader.name }}"
    >
      <ng-template ngx-datatable-header-template let-sort="sortFn">
        <span (click)="sort()">{{ tableHeader.label }}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngFor="let colmn of actionColumns"
      [resizeable]="false"
      [sortable]="false"
      name="{{ colmn.name }}"
    >
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <div
          *ngIf="colmn.type === 'acceptance'"
          style="display: inline-flex; width: 100%"
        >
          <div *ngIf="stateType == 'alianza'">
            <div
              *ngFor="let value of colmn.values"
              [ngStyle]="{
                display: row[colmn.key] == value.value ? 'block' : 'none'
              }"
              style="width: 100%"
            >
              <label
                *ngIf="row[colmn.key] == value.value"
                [class]="value.style"
                >{{ value.label }}</label
              >
            </div>
          </div>
          <div>
            <a
              (click)="actionEmit(button.action, row)"
              *ngFor="let button of colmn.buttons"
              [class]="button.style"
            >
              {{ !button.icon ? button.label : "" }}
              <i *ngIf="button.icon" [class]="button.icon"></i>
            </a>
          </div>
        </div>
        <div *ngIf="colmn.type === 'Accept'">
          <button
            (click)="actionEmit(colmn.action, row)"
            *ngIf="colmn.condition == null ? true : colmn.condition(row)"
            [class]="colmn.style"
          >
            {{ colmn.title }}
          </button>
        </div>
        <div *ngIf="stateType == 'alianza'">
          <div *ngFor="let act of actions">
            <div class="content-button">
              <button
                (click)="
                  actionEmit(act.action, act.key == null ? row : row[act.key])
                "
                *ngIf="act.condition == null ? true : act.condition(row)"
                [class]="act.style"
              >
                <i [class]="act.icon"></i>
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="stateType && stateType != 'alianza'"
      name="{{ stateType == 'bienestar' ? '' : 'Estado' }}"
      prop="id"
      [sortable]="false"
      [resizeable]="false"
    >
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <div
          class="state"
          [ngClass]="{
            'state-agente': stateType == 'agente'
          }"
        >
          <p
            *ngIf="stateType == 'bienestar'"
            [ngClass]="{
              'text-yellow': row.status_asignado === 'EN PROCESO',
              'text-red': row.status_asignado === 'RECHAZADO',
              'text-green': row.status_asignado === 'ACEPTADO'
            }"
          >
            {{ row.status_asignado }}
          </p>
          <p
            style="width: 120px"
            *ngIf="stateType == 'monitor' || stateType == 'agente'"
            [ngClass]="{
              'text-blue':
                row.statusNombre === 'EN VIGENCIA' ||
                row.statusNombre === 'FUERA VIGENCIA' ||
                row.statusNombre === 'Aceptado',
              'text-red':
                row.statusNombre === 'CANCELADA' ||
                row.statusNombre === 'Rechazado',
              'text-yellow': row.statusNombre === 'En Proceso'
            }"
            [class]="row.estadoColor"
            [ngStyle]="{ color: row.estadoColor }"
          >
            {{ row.statusNombre }}
          </p>

          <div style="display: flex; align-items: center">
            <div *ngFor="let act of actions">
              <button
                (click)="
                  actionEmit(act.action, act.key == null ? row : row[act.key])
                "
                *ngIf="act.condition == null ? true : act.condition(row)"
                [class]="act.style"
              >
                <i [class]="act.icon"></i>
              </button>
            </div>
            <p
              style="margin-left: 10px"
              *ngIf="stateType == 'monitor'"
              [ngClass]="{
                'text-yellow': row.semaforo === 'amarillo',
                'text-red': row.semaforo === 'rojo',
                'text-green': row.semaforo === 'verde'
              }"
              [ngStyle]="{ color: row.semaforo }"
            >
              <i class="fas fa-circle"></i>
            </p>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>

<div *ngIf="noDataError" class="notFound">
  <div class="warning">
    <i class="fas fa-exclamation-triangle"></i>
    <h2 class="text-medium">No se han encontrado resultados</h2>
  </div>

  <p *ngIf="!msjError">
    Lo sentimos, no hemos encontrado ningún resultado con esta información.
    <br />
    Por favor, valida nuevamente tus datos o comunícate al teléfono
    <a [href]="'tel: 55 5723 7999'">55 5723 7999</a> , si lo <br />
    prefieres escríbenos al correo
    <a
      [href]="
        'mailto:' + customEmail
          ? customEmail
          : 'mxreembolso.beneficios@segurossura.com.mx'
      "
      >{{
        customEmail ? customEmail : "mxreembolso.beneficios@segurossura.com.mx"
      }}</a
    >
    <!-- siniestrosvida@segurossura.com.mx -->
    <!-- mxreembolso.beneficios@segurossura.com.mx -->
  </p>

  <p *ngIf="msjError">
    {{ msjError }}
  </p>
</div>

import { SharedModule } from 'src/app/shared/shared.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderAlianzaComponent } from './shared/header-alianza/header-alianza.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { HomeComponent } from './home/home.component';
import { ErrorComponent } from './error/error/error.component';
import { Interceptor } from 'src/app/interceptor.service';


@NgModule({
  declarations: [AppComponent, HeaderAlianzaComponent, HomeComponent, ErrorComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,    
    HttpClientModule,
    SharedModule,
    NgxSmartModalModule.forRoot(),
  ],
  
  providers: [DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },],
  bootstrap: [AppComponent],
})
export class AppModule {}

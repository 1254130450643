import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConstants } from 'src/app/constants';
import { getFormattedDate } from 'src/functions/common-functions';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  _baseURL: string;
  loginURL: string;

  constructor(private http: HttpClient, private datepipe: DatePipe) {
    this._baseURL = AppConstants.apiBienestar;
  }

  verifyToken(token): Observable<any> {
    const url = `${this._baseURL}/auth/user/verificar-token`;

    var formData = new FormData();
    formData.append('refresh_token', token);

    return this.http.post(url, formData).pipe(map((data) => data));
  }

  getUserInfo(token): Observable<any> {
    const url = `${this._baseURL}/auth/user/info`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: token,
        }),
      })
      .pipe(map((data) => data));
  }

  setUser(user) {
    let user_string = JSON.stringify(user);
    localStorage.setItem('current_user', user_string);
  }

  setUserInfo(userInfo) {
    let user_string = JSON.stringify(userInfo);
    localStorage.setItem('user_info', user_string);
  }

  setToken(token) {
    localStorage.setItem('userToken', 'Bearer ' + token);
  }

  getCurrentUser() {
    let user_string = localStorage.getItem('current_user');
    if (user_string) {
      let user = JSON.parse(user_string);
      return user;
    } else {
      return null;
    }
  }

  getCurrentUserInfo() {
    let user_string = localStorage.getItem('user_info');
    if (user_string) {
      let user = JSON.parse(user_string);
      return user;
    } else {
      return null;
    }
  }

  getUserToken() {
    let user_token = localStorage.getItem('userToken');
    if (user_token) {
      // let user = JSON.parse(user_token)
      return user_token;
    } else {
      return null;
    }
  }

  getDataSupplier(token): Observable<any> {
    const url = `${this._baseURL}/v1/proveedor/ver`;
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: token,
        }),
      })
      .pipe(map((data) => data));
  }

  logout(): Observable<any> {
    const url = `${this._baseURL}/auth/user/logout`;

    return this.http
      .post(url, null, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getToken() {
    return localStorage.getItem('userToken');
  }

  getConceptosValoracion(queryParams): Observable<any> {
    const url = `${this._baseURL}/v1/proveedor/conceptos-valoracion${queryParams}`;
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getRamosPagos(): Observable<any> {
    const url = `${this._baseURL}/v1/proveedor/ramos-pagos`;
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getCatalogoOficina(): Observable<any> {
    const url = `${this._baseURL}/catalogos/oficinas`;
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getCatalogoRamos(): Observable<any> {
    const url = `${this._baseURL}/v1/proveedor/ramos`;
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  validRFC(rfc): Observable<any> {
    const url = `${this._baseURL}/v1/proveedor/cdperson-rfc`;
    const body = { rfc };
    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getCuentaBancaria(cdperson): Observable<any> {
    const url = `${this._baseURL}/catalogos/cuentas-bancarias?cdperson=${cdperson}`;
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  realizarPagoProveedor(body): Observable<any> {
    const url = `${this._baseURL}/v1/proveedor/pago`;
    var formData = new FormData();

    formData.append('oficina', body.oficina);
    formData.append('ramo', body.ramo);
    formData.append('annio', body.annio);
    formData.append('siniestro', body.siniestro);
    formData.append('rfc', body.rfc);
    formData.append('cuentaBancaria', body.cuentaBancaria);
    formData.append('conceptoValoracion', body.conceptoValoracion);
    formData.append('factura', body.factura);

    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getCatalogoValoracion(p): Observable<any> {
    const url = `${this._baseURL}/v1/proveedor/conceptos-valoracion?ramos=${p}`;
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  subirCatalogo(body): Observable<any> {
    const url = `${this._baseURL}/v1/proveedor/subir-catalogo`;
    var formData = new FormData();

    formData.append('file', body.file);

    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }
}

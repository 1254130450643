import { Status } from 'src/app/shared/file-manager/file-manager.interface';
import { ApiAlianzaService } from './api-alianza.service';
import { Proveedor } from './../models/proveedor.model';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GestionProveedorService {
  proveedor: Proveedor;
  activeStatus: boolean;    
  listDocument: any[] = []  
  ListDocUrl: any[] = []
  tam:number
  index:number = 0
  valid:boolean
  cantDocLoad:number = 0
  arraySteps: Array<boolean> = [true, true, true, true, true, true, true, true];
  actualStep: number = 0;
  docTemp:any
  valueListDocument = new Subject
  constructor(public apiA:ApiAlianzaService) {}
  next() {
    window.scrollTo(0,0)
    this.actualStep++;    
    if(this.actualStep + 1 == 5){
      this.tam = 0
      this.listDocument = []
      this.apiA.getUploadDocuments().subscribe(
        (resp)=>{          
          // console.log('Documentacion');          
          console.log(resp);                    
          
          
          resp.forEach(element => {
            this.index = this.index + 1
              let document = {                
                codDoc: element.codigo,                            
                formato: element.formatoDocumento,
                id: element.id,
                obligatorio: true,
                nombre: element.nombreDocumento +' - '+element.pesoDocumento,
                plantilla: element.plantilla,
                tamanio: element.pesoDocumento,                
                url:'',
                fileInfo:{
                  name:element.nombreDocumento +' - '+element.pesoDocumento,
                  uploaded:'',
                  url: '',
                  codDoc: element.codigo,     
                  id: element.id,
                },
                // status: Status.OBLIGATORIO,
                status: 'notLoaded',
                actions: {
                  motivo: false,
                  validar: false,
                  ver: false,
                  descargar: false,
                  subir:true,
                  expandir: false,
                },
                input: {
                  type: 'DATE',
                  label: 'INGRESE LA FECHA DE EXPEDICIÓN',
                },
              }
              
              // if(element.obligatorio == true){
              //   this.tam = this.tam + 1
              // }
              
              this.tam = this.tam + 1
              this.listDocument.push(document)
          });
          // console.log(this.listDocument);    
                          
          

      var docProveedor = this.proveedor.documentosProveedor           
      this.listDocument.forEach(listDoc =>{
        if(docProveedor){          
          let x = docProveedor.find(x => x.codDoc == listDoc.codDoc)
            if(x){
              listDoc.url = x.url     
              // listDoc.status = Status.CARGADO                           
            }
        }
      })

        },
        (error)=>{
          console.log(error);
          
        }
      )
      
      console.log('Documentos con url',this.listDocument);
      
            
    }



    if(this.actualStep == 5){

      this.apiA.getUploadDocuments().subscribe(
        (resp)=>{
          if(resp.length == 0){
            this.valueListDocument.next(true)
          }else{
            this.valueListDocument.next(false)
          }
        },
        (error)=>{
          console.log(error);          
        }
      )
      // console.log('documentos ya cargados del proveedor:',this.proveedor.documentosProveedor);                                  
      // console.log('Documentos con url',this.listDocument);

      this.cantDocLoad = 0
      this.listDocument.forEach(listDoc => {
        // console.log(listDoc);        
        if(listDoc.obligatorio == true){
          /** hacer el cambio por Status.CARGADO */
          if(listDoc.status == 'loaded'){
            // if(listDoc.status == Status.CARGADO){
            this.cantDocLoad = this.cantDocLoad + 1
          }          
        }              
      });
    }

    // console.log('documentos con url:',this.ListDocUrl);
    

    let checkArrays = true

    for (let index = 0; index < this.listDocument.length; index++) {
      const element = this.listDocument[index];
      if(element.obligatorio == true){
        // Hacer el cambio
        // if(element.status != Status.CARGADO){
        if(element.status != 'loaded'){
          checkArrays = false
        }
      }
    }

    this.valid = checkArrays
    console.log('Servicio valid',this.valid);
    

    if (this.arraySteps[this.actualStep] == false) {      
      this.next();
    }
  }
  back() {
    this.actualStep--;
    if (this.arraySteps[this.actualStep] == false) {
      this.back();
    }
  }
}

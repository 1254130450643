<div *ngIf="!noDataError">
  <div class="table-top-bar basic">
    <div class="search-container mb-3" *ngIf="title">
      <label class="mb-4 text-bold-medium">{{ title }}</label>
    </div>

    <!-- <div class="entries">
    <select [(ngModel)]="entriesVal">
      <option *ngFor="let e of entries" [ngValue]="e">{{ e }}</option>
    </select>
    <span>Entradas</span>
  </div> -->
  </div>
  <ngx-datatable
    #table
    class="material basic"
    [rows]="_rows"
    [columnMode]="ColumnMode.force"
    [headerHeight]="50"
    [footerHeight]="50"
    rowHeight="auto"
    [selected]="selected"
    (activate)="onActivate($event)"
    (select)="onSelect($event)"
    [selectCheck]="checkSelectable"
    [limit]="10"
    [offset]="offset"
    [externalPaging]="serverSidePagination"
    [count]="totalRows"
    (page)="setPage($event)"
  >
    <ngx-datatable-column
      *ngFor="let tableHeader of tableHeaders"
      name="{{ tableHeader.name }}"
    >
      <ng-template ngx-datatable-header-template let-sort="sortFn">
        <span (click)="sort()">{{ tableHeader.label }}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [resizeable]="false"
      [sortable]="false"
      name="Estatus"
      *ngIf="stateType === 'estado'"
    >
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <div class="action-container" style="display: flex">
          <p
            class="text-bold"
            [ngClass]="{
              'text-primary': row.estado.color === 'azul'
            }"
            [ngStyle]="{ color: row.estado.color }"
          >
            {{ row.estado.nombre }}
          </p>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [resizeable]="false"
      [sortable]="false"
      name="% de aprobación"
      *ngIf="stateType === 'porcAprobacion'"
    >
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <div class="action-container" style="display: flex">
          <p
            [ngClass]="{
              'text-primary': row.porcAprobacion.color === 'azul'
            }"
            [ngStyle]="{ color: row.porcAprobacion.color }"
          >
            {{ row.porcAprobacion.nombre }}
          </p>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [resizeable]="false"
      [sortable]="false"
      name="Acciones"
    >
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <div class="action-container" style="display: flex">
          <div *ngFor="let action of row.actions">
            <i
              [class]="action.icon"
              [style]="'color: ' + action.color + ';'"
              [title]="action.action === 'tooltip' ? action.label : ''"
              (click)="emitActionColumn(row, action)"
              [ngClass]="{
                disabled: action.disabled,
                'text-danger': action.color === 'rojo',
                'text-yellow': action.color === 'amarillo',
                'text-green': action.color === 'verde'
              }"
            ></i>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>

<div
  *ngIf="noDataError"
  class="flex-container"
  class="flex-container bg-danger text-danger pr-5 pl-5 mt-3"
>
  <div style="margin-left: -10px">
    <i class="fas fa-exclamation-circle"></i>
    <label style="margin: 0 10px"
      >No se han obtenido resultados de esta búsqueda</label
    >
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { Breadcrumb } from './breadcrumb.interface';

@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadcrumbs-global.component.html',
  styleUrls: ['./breadcrumbs-global.component.scss'],
})
export class BreadcrumbsGlobalComponent implements OnInit {
  @Input() breadcrumbs: Breadcrumb;

  constructor() {}

  ngOnInit(): void {}
}

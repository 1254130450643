import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'navbar-blue',
  templateUrl: './navbar-blue.component.html',
  styleUrls: ['./navbar-blue.component.scss'],
})
export class NavbarBlueComponent implements OnInit {
  @Input() menu;
  @Input() descriptor;

  list: boolean = false;
  constructor(private router: Router) {}

  ngOnInit(): void {}

  openList() {
    if (this.list) {
      this.list = false;
    } else {
      this.list = true;
    }
  }

  navigateTo(route) {
    setTimeout(() => {
      this.router.navigate([route]);
    }, 1);
  }

  customNav(menu) {
    if (menu === 'Localizar Servicios de Salud')
      window.open('https://www.segurossura.com.mx/red-medica/');
  }
}

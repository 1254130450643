import { ApiService } from './../../services/api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConstants } from 'src/app/constants';

@Injectable({
  providedIn: 'root',
})
export class ApiAlianzaService {
  _baseURL: string;

  constructor(private http: HttpClient) {
    this._baseURL = AppConstants.apiBienestar;
  }

  getToken() {
    return localStorage.getItem('userToken');
  }

  getNacionalidades(): Observable<any> {
    const url = `${this._baseURL}/catalogos/nacionalidades`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }
  getSexo(tipo_persona): Observable<any> {
    const url = `${this._baseURL}/catalogos/sexos?tipo_persona=${tipo_persona}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }

  getColonias(codigoPostal: any): Observable<any> {
    const url = `${this._baseURL}/catalogos/colonias?codigo_postal=${codigoPostal}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }

  getSiglas(): Observable<any> {
    const url = `${this._baseURL}/catalogos/siglas-domicilio`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }

  getLocalidad(codigoPostal: any): Observable<any> {
    const url = `${this._baseURL}/catalogos/localidad?codigo_postal=${codigoPostal}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }

  getListProveedor(): Observable<any> {
    const url = `${this._baseURL}/v1/tipo-proveedor/listar`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  // getListOcupation(): Observable<any> {
  //   const url = `${this._baseURL}/v1/ocupaciones`;

  //   return this.http
  //     .get(url, {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json',
  //         Accept: 'application/json',
  //         Authorization: this.getToken(),
  //       }),
  //     })
  //     .pipe(map((data) => data));
  // }

  getListOcupation(idTypeProveedor): Observable<any> {
    const url = `${this._baseURL}/v1/ocupaciones/listar?tipoProveedorId=${idTypeProveedor}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getCoin(): Observable<any> {
    const url = `${this._baseURL}/catalogos/monedas`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getBanks(): Observable<any> {
    const url = `${this._baseURL}/catalogos/bancos`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }),
      })
      .pipe(map((data) => data));
  }

  getContractPDF(id: number): Observable<any> {
    const url = `${this._baseURL}/proveedor/generate/contrato/${id}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: 'application/pdf',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getUploadDocuments(): Observable<any> {
    const url = `${this._baseURL}/v1/documentos-proveedor/listado`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  updateProveedor(body): Observable<any> {
    const url = `${this._baseURL}/v1/proveedor/editar`;

    return this.http
      .put(url, body, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  deleteSucursal(id: number): Observable<any> {
    const url = `${this._baseURL}/v1/sucursales/${id}`;

    return this.http
      .delete(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  accountProveedor(json, id: number): Observable<any> {
    const url = `${this._baseURL}/v1/proveedor/cuentas-bancarias`;

    var formData = new FormData();

    formData.append('tipoPago', json.tipoPago);
    formData.append('tipoMoneda', json.tipoMoneda);
    formData.append('banco', json.banco);
    formData.append('clabe', json.clabe);    
    formData.append('edoCuentaFecha',json.fecha)
    if(json.ine){
      formData.append('ine', json.ine.file);
    }else{
      formData.append('ine', null);
    }    
    if(json.edoCuenta){
      formData.append('edoCuenta', json.edoCuenta.file);
    }else{
      formData.append('edoCuenta', null);
    }
    if(json.compDom){
      formData.append('compDomicilio', json.compDom.file);
    }else{
      formData.append('compDomicilio', null);
    }
    formData.append('idCuenta',json.idCuenta ? json.idCuenta : '' )
    
    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getContract():Observable<any>{
    const url = `${this._baseURL}/v1/proveedor/generar-contrato`;

    return this.http
    .get(url,{
      headers: new HttpHeaders({
        
        Accept: '*/*',
        Authorization: this.getToken(),
      }),
    })
    .pipe(map((data) => data));
  }

  uploadContract(json): Observable<any> {
    // const url = `${this._baseURL}/proveedor/contrato/subir`;
    const url = `${this._baseURL}/v1/contratos-proveedor/subir`;

    var formData = new FormData();

    formData.append('file', json.archivo);

    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  
  uploadDocuments(
    nombreDoc,
    archivo,
    codDoc,
    id
    
  ):Observable<any>{
    let formData = new FormData()
    formData.append('nombreDoc',nombreDoc)
    formData.append('archivo',archivo)
    formData.append('codDoc',codDoc)
    formData.append('idDocGestor',id)
    // formData.append('FechaUsuario',fechaUsuario)
    
    // const url = `${this._baseURL}/documentos-proveedor/subir`;
    const url = `${this._baseURL}/v1/documentos-proveedor/subir`

    return this.http
    .post(url, formData,{
      headers: new HttpHeaders({
        Accept: '*/*',
        Authorization: this.getToken(),
      })
    })
    .pipe(map((data)=> data))

  }

  resetDocuments():Observable<any>{
    const url = `${this._baseURL}/v1/documentos-proveedor/reestablecer`;    
    
    return this.http
    .post(url,'',{
      headers: new HttpHeaders({
        Accept: '*/*',            
        Authorization: this.getToken()
      })
    })
    .pipe(map((data)=> data))
  }

/**
 * Nueva lista endpoints 
 * */  

  paymentList(dateFrom,dateTo,factura):Observable<any>{
    const url = `${this._baseURL}/v1/proveedor/pagos?fechaDesde=${dateFrom}&fechaHasta=${dateTo}&factura=${factura}`; 
    
    return this.http
    .get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: '*/*',
        Authorization: this.getToken(),
      }),
    })
    .pipe(map((data) => data));    
  }

  paymentListMonitor(dateFrom,dateTo,factura,id):Observable<any>{
    const url = `${this._baseURL}/v1/proveedores/${id}/pagos?fechaDesde=${dateFrom}&fechaHasta=${dateTo}&factura=${factura}`; 
    
    return this.http
    .get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: '*/*',
        Authorization: this.getToken(),
      }),
    })
    .pipe(map((data) => data));    
  }

  deleteAccBank(id:number):Observable<any>{
    const url = `${this._baseURL}/v1/proveedor/cuentas-bancarias/${id}/eliminar`; 

    return this.http
    .delete(url,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: '*/*',
        Authorization: this.getToken(),
      }),
    })
    .pipe(map((data) => data));    
  }

  editAccBank(json,id:number):Observable<any>{
    const url = `${this._baseURL}/v1/proveedor/cuentas-bancarias/${id}/editar`;

    var formData = new FormData()

    formData.append('ine',json.ine.file)
    formData.append('edoCuenta',json.edoCuenta.file)
    formData.append('compDomicilio',json.compDomicilio.file)
    formData.append('edoCuentaFecha',json.edoCuentaFecha)

    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data)=> data))
  }

  addAccBank(json):Observable<any>{
    const url = `${this._baseURL}/v1/proveedor/cuentas-bancarias`;

    var formData = new FormData()

    formData.append('tipoPago',json.tipoPago)
    formData.append('tipoMoneda',json.tipoMoneda)
    formData.append('banco',json.banco)
    formData.append('clabe',json.clabe)
    formData.append('ine',json.ine.file)
    formData.append('edoCuenta',json.edoCuenta.file)
    formData.append('compDomicilio',json.compDomicilio.file)
    formData.append('edoCuentaFecha',json.edoCuentaFecha)

    return this.http
      .post(url, formData,{
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data)=> data))
  }

  accFavorite(id:number):Observable<any>{
    const url = `${this._baseURL}/v1/proveedor/cuentas-bancarias/${id}/preferida`;    
    return this.http
      .post(url,{}, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data)=> data))
  }

  devolucionDocument(body):Observable<any>{
    const url = `${this._baseURL}/v1/documentos-proveedor/devolucion`;

    
    var formData = new FormData()

    for (let index = 0; index < body.length; index++) {
      if(body[index].file){
        let fileKey = 'file['+index+']'
        let idKey = 'id['+index+']'
        
        formData.append(fileKey,body[index].file)
        formData.append(idKey,body[index].id)
      }
    }
    

    return this.http
      .post(url,formData, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data)=> data))

  }
  

  // updateProveedor(body,): Observable<any> {
  //   const url = `${this._baseURL}/proveedor/editar`;

  //   return this.http
  //     .put(url, body, {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json',
  //         Accept: 'application/json',
  //         Authorization: this.getToken(),
  //       }),
  //     })
  //     .pipe(map((data) => data));
  // 

}

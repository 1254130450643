<div [ngClass]="{ disabled: disabled }">
  <div
    class="row"
    style="background-color: white"
    [ngClass]="{
      vencido: vigencia == false,
      rechazado: status == 'RECHAZADO',
      first: first
    }"
  >
    <!-- STATUS -->
    <div
      *ngIf="status == 'NUEVO'"
      class="status nuevo"
      [ngStyle]="{ color: colorStatus }"
    >
      <i class="fas fa-history"></i>
      <span>NUEVO</span>
    </div>
    <div
      *ngIf="status == 'VALIDADO'"
      class="status validado"
      [ngStyle]="{ color: colorStatus }"
    >
      <i class="fas fa-check-circle action"></i>
      <span>VALIDADO</span>
    </div>
    <div
      *ngIf="status == 'CARGADO'"
      class="status cargado"
      [ngStyle]="{ color: colorStatus }"
    >
      <i class="fas fa-check-circle action"></i>
      <span>CARGADO</span>
    </div>
    <div
      *ngIf="status == 'ERROR'"
      class="status error"
      [ngStyle]="{ color: colorStatus }"
    >
      <i class="fas fa-check-circle action"></i>
      <span>ERROR</span>
    </div>
    <div
      *ngIf="status == 'VIGENTE'"
      class="status vigente"
      [ngStyle]="{ color: colorStatus }"
    >
      <i class="fas fa-check-circle action"></i>
      <span>VIGENTE</span>
    </div>
    <div
      *ngIf="status == 'LOADING'"
      class="status loading"
      [ngStyle]="{ color: colorStatus }"
    >
      <i class="fas fa-sync fa-spin"></i>
      <span>SUBIENDO</span>
    </div>
    <div
      *ngIf="status == 'VENCIDO'"
      class="status vencido"
      [ngStyle]="{ color: colorStatus }"
    >
      <i class="fas fa-check-circle action"></i>
      <span>VENCIDO</span>
    </div>

    <div
      *ngIf="status == 'RECHAZADO'"
      class="status rechazado"
      [ngStyle]="{ color: colorStatus }"
    >
      <i class="fas fa-check-circle action"></i>
      <span>RECHAZADO</span>
    </div>

    <div
      *ngIf="status == 'OBLIGATORIO'"
      class="status obligatorio"
      [ngStyle]="{ color: colorStatus }"
    >
      <span>OBLIGATORIO</span>
    </div>
    <div
      *ngIf="status == 'OPCIONAL'"
      class="status opcional"
      [ngStyle]="{ color: colorStatus }"
    >
      <span>OPCIONAL</span>
    </div>

    <div
      *ngIf="status == 'DISPONIBLE'"
      class="status"
      [ngStyle]="{ color: colorStatus }"
    >
      <i class="fas fa-check-circle action"></i>
      <span>DISPONIBLE</span>
    </div>

    <div
      *ngIf="status == 'NODISPONIBLE'"
      class="status"
      [ngStyle]="{ color: colorStatus }"
    >
      <i class="fas fa-check-circle action"></i>
      <span>NO DISPONIBLE</span>
    </div>
    <div
      *ngIf="status == 'ENPROCESO'"
      class="status"
      [ngStyle]="{ color: colorStatus }"
    >
      <i class="fas fa-clock"></i>
      <span>EN PROCESO</span>
    </div>

    <div
      *ngIf="status == 'DEVUELTO'"
      class="status"
      [ngStyle]="{ color: colorStatus }"
    >
      <i class="fas fa-clock"></i>
      <span>DEVUELTO</span>
    </div>

    <div
      *ngIf="status == 'REVISAR ACTUALIZACIÓN'"
      class="status"
      [ngStyle]="{ color: colorStatus }"
    >
      <i class="fas fa-clock"></i>
      <span>REVISAR ACTUALIZACIÓN</span>
    </div>

    <!-- FILE INFO -->
    <div class="file-info">
      <span class="text-bold"> {{ fileInfo?.name }} </span>
      <span *ngIf="fileInfo?.uploaded?.length > 0" style="margin: 0 5px"
        >-</span
      >
      <span>{{ fileInfo?.uploaded }} </span>
    </div>

    <!-- INPUT -->
    <span *ngIf="input" class="text-bold">{{ input.label }} </span>
    <div *ngIf="input?.type == 'TEXT'">
      <div class="form-group">
        <ng-content select="[input-text]"></ng-content>
      </div>
    </div>

    <div
      class="form-group"
      style="position: relative"
      *ngIf="input?.type == 'DATE'"
    >
      <ng-content select="[input-date]"></ng-content>
    </div>

    <!-- <div *ngIf="input?.type == 'TEXT'">
      <div class="form-group">
        <ng-content select="[asegurado=input-text]"></ng-content>
      </div>
    </div> -->

    <!-- <div
      class="form-group"
      style="position: relative"
      *ngIf="input2?.type == 'DATE'"
    >
      <span *ngIf="input" class="text-bold">{{ input2.label }} </span>
      <ng-content select="[asegurado=input-date]"></ng-content>
    </div> -->

    <!-- ACTIONS -->
    <div class="actions">
      <div
        class="motivo"
        *ngIf="actions?.motivo && status != 'VALIDADO' && !actions?.adicional"
      >
        <div class="form-group">
          <select
            name="motivo"
            class="form-control"
            [disabled]="blockDevuelto"
            [(ngModel)]="motivo"
            (change)="emitAction('motivo')"
            required
          >
            <option value="" selected>
              Seleccione el motivo de Devolución Administrativa
            </option>

            <option *ngFor="let item of actions.motivo" [value]="item.id">
              {{ item.descripcion }}
            </option>
          </select>
        </div>
      </div>

      <div class="motivo" *ngIf="actions?.adicional && !actions?.url">
        <div class="form-control" style="margin: 0 15px">
          <div class="text-motivo">
            <p class="text-bold w-100">{{ actions?.adicional }}</p>
          </div>
        </div>
      </div>
      <div
        class="motivo"
        *ngIf="actions?.adicional && status != 'VALIDADO' && actions?.url"
      >
        <div class="form-group">
          <select
            name="motivo"
            class="form-control"
            [disabled]="blockDevuelto"
            [(ngModel)]="motivo"
            (change)="emitAction('motivo')"
            required
          >
            <option value="" selected>
              Seleccione el motivo de Devolución Administrativa
            </option>

            <option *ngFor="let item of actions.motivo" [value]="item.id">
              {{ item.descripcion }}
            </option>
          </select>
        </div>
      </div>
      <i
        class="fas fa-check-circle action"
        [ngClass]="{ 'text-green': status == 'VALIDADO' }"
        [ngStyle]=""
        *ngIf="actions?.validar"
        (click)="blockDevuelto ? null : emitAction('validar')"
      ></i>
      <!-- class="fas fa-eye action disabled-icon" -->
      <i
        class="fas fa-eye action"
        *ngIf="actions?.ver"
        (click)="blockDevuelto ? null : emitAction('ver')"
      ></i>
      <i
        class="fas fa-download action"
        *ngIf="actions?.descargar"
        (click)="blockDevuelto ? null : emitAction('descargar')"
      ></i>
      <i
        class="fas fa-folder-open action"
        *ngIf="actions?.subir"
        (click)="uploader.click()"
      ></i>
      <i
        class="fas fa-expand-arrows-alt action font-special"
        *ngIf="actions?.expandir"
        (click)="blockDevuelto ? null : emitAction('expandir')"
      ></i>
    </div>
  </div>
</div>

<div class="form-group" *ngIf="input == 'motivo'">
  <ng-content select="[reason-return]"></ng-content>
</div>

<div class="form-group" *ngIf="expandir == true">
  <ng-content select="[multi-inputs]"></ng-content>
</div>

<div
  class="form-group"
  *ngIf="
    motivo == 'Documento no legible' ||
    motivo == 'Documento no vigente (mayor a 6 meses)' ||
    motivo == 'Informe médico incompleto' ||
    motivo == 'Aviso de accidente incompleto' ||
    motivo == 'Receta médica no válida' ||
    motivo == 'Estudios médicos no válidos' ||
    motivo == 'Otros' ||
    adicional
  "
>
  <ng-content select="[details-return]"></ng-content>
</div>

<div class="form-group" *ngIf="input == 'Informe médico'">
  <ng-content select="[details-informe]"></ng-content>
</div>

<input
  (change)="fileChangeEvent($event.target.files)"
  (click)="uploader.value = null"
  #uploader
  type="file"
  name="myImage"
/>

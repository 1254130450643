<div class="main" *ngIf="type === 'template'">
  <div class="centered">
    <div class="buttons">
      <div class="action-button">
        <i class="far fa-file-alt"></i>
      </div>
    </div>
    <div class="title">
      <p>
        {{ title }}
      </p>
      <p [innerHTML]="condition"></p>

      <p>
        {{ format }}
      </p>
    </div>
  </div>
</div>

<!-- ************* LOAD ************* -->
<ngx-file-drop
  dropZoneLabel="Drop files here"
  (onFileDrop)="dropped($event)"
  (onFileLeave)="fileLeave($event)"
  *ngIf="!file?.name && type === 'load'"
  class="dnd-original"
>
  <ng-template
    ngx-file-drop-content-tmp
    let-openFileSelector="openFileSelector"
  >
    <div class="center-container">
      <div class="buttons">
        <div class="action-button" (click)="openFileSelector()">
          <i class="fas fa-plus"></i>
        </div>
      </div>
      <div class="title">
        <p>
          {{ title }}
        </p>
        <p>
          {{ condition }}
        </p>
        <p>
          {{ format }}
        </p>
      </div>
    </div>
  </ng-template>
</ngx-file-drop>

<div
  [ngClass]="{ 'loaded-block': block }"
  class="main loaded"
  *ngIf="file.name != '' && type != 'load-date'"
>
  <div class="centered">
    <div class="buttons">
      <div class="action-button checked">
        <i class="fas fa-check-circle"></i>
      </div>
    </div>
    <div class="title">
      <p>
        {{ title }}
      </p>
      <span class="fileName">
        {{
          file?.name.length > 30
            ? (file?.name | slice: 0:30) + "..."
            : file?.name
        }}
      </span>
      <p *ngIf="file.size">{{ file.size }} MB</p>
    </div>
  </div>

  <div class="actions">
    <i
      class="fas fa-eye"
      title="Ver archivo"
      (click)="watchFile()"
      *ngIf="!initURL"
    ></i>
    <i class="fas fa-download" title="Descargar" (click)="downloadNew()"></i>
    <i class="fas fa-trash-alt" title="Borrar" (click)="deleteFile()"></i>
  </div>
</div>
<!-- ************* LOAD ************* -->

<!-- ************* DOWNLOAD ************* -->
<ngx-file-drop
  dropZoneLabel="Drop files here"
  (onFileDrop)="dropped($event)"
  (onFileLeave)="fileLeave($event)"
  *ngIf="!file?.name && type === 'load-download'"
  class="dnd-original"
>
  <ng-template
    ngx-file-drop-content-tmp
    let-openFileSelector="openFileSelector"
  >
    <div class="center-container">
      <div class="buttons">
        <div class="action-button" (click)="openFileSelector()">
          <i class="fas fa-plus"></i>
        </div>
        <abbr data-title="Descargar plantilla">
          <a>
            <div class="action-button download" (click)="downloadFile()">
              <i class="fas fa-download"></i>
            </div>
          </a>
        </abbr>
      </div>
      <div class="title">
        <p>
          {{ title }}
        </p>
        <p>
          {{ condition }}
        </p>
      </div>
    </div>
  </ng-template>
</ngx-file-drop>
<!-- ************* DOWNLOAD ************* -->

<!-- ************* LOAD-DATE ************* -->
<ngx-file-drop
  dropZoneLabel="Drop files here"
  (onFileDrop)="dropped($event)"
  (onFileLeave)="fileLeave($event)"
  *ngIf="!file?.name && type === 'load-date'"
  class="dnd-original"
>
  <ng-template
    ngx-file-drop-content-tmp
    let-openFileSelector="openFileSelector"
  >
    <div class="center-container">
      <div class="buttons">
        <div class="action-button" (click)="openFileSelector()">
          <i class="fas fa-plus"></i>
        </div>
      </div>
      <div class="title">
        <p>
          {{ title }}
        </p>
        <p>
          {{ condition }}
        </p>
        <p>
          {{ format }}
        </p>
      </div>
      <!-- <input
        placeholder="Fecha de Expedición"
        onfocus="(this.type='date')"
        type="text"
        class="form-control"
        style="text-align: center;"
      /> -->
      <app-date-picker
        [placeholder]="'Fecha de Expedición (*)'"
        (action)="validateDP($event)"
        [dateString]="dateString"
        [clear]="dateClear"
        [max]="today | date: 'yyyy/MM/dd'"
      ></app-date-picker>
    </div>
  </ng-template>
</ngx-file-drop>

<div class="main loaded" *ngIf="file.name != '' && type === 'load-date'">
  <div class="centered">
    <div class="buttons">
      <div class="action-button checked">
        <i class="fas fa-check-circle"></i>
      </div>
    </div>
    <div class="title">
      <p>
        {{ title }}
      </p>
      <span class="fileName">
        {{
          file?.name.length > 30
            ? (file?.name | slice: 0:30) + "..."
            : file?.name
        }}
      </span>
      <p *ngIf="file.size">{{ file.size }} MB</p>
    </div>

    <app-date-picker
      [placeholder]="'Fecha de Expedición (*)'"
      (action)="validateDP($event)"
      [dateString]="dateString"
      [clear]="dateClear"
      [max]="today | date: 'yyyy/MM/dd'"
    ></app-date-picker>
  </div>

  <div class="actions">
    <i
      class="fas fa-eye"
      title="Ver archivo"
      (click)="watchFile()"
      *ngIf="!initURL"
    ></i>
    <i class="fas fa-download" title="Descargar" (click)="downloadNew()"></i>
    <i class="fas fa-trash-alt" title="Borrar" (click)="deleteFile()"></i>
  </div>
</div>

<!-- ************* LOAD-DATE ************* -->

<a id="dllink" style="visibility: hidden; position: absolute; left: 5000px"></a>

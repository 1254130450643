import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss'],
})
export class FileManagerComponent implements OnInit {
  @Input() status: any;
  @Input() colorStatus: string;
  @Input() fileInfo: any;
  @Input() actions: any;
  @Input() input: any;
  @Input() input2: any;
  @Input() index: any;
  @Input() disabled: boolean;
  @Input() first: boolean;
  @Input() motivo: any;
  @Input() vigencia: any;
  @Input() adicional: any;
  @Input() blockDevuelto: any;
  @Input() expandir: boolean;
  @Output() fileManagerEvent = new EventEmitter<any>();

  // motivo:any = ''

  constructor() {}

  ngOnInit(): void {}

  changeDP(e) {}

  ngOnChanges(changes: SimpleChanges): void {
    // Porrazo fix reset select - Valero me debe unas curdas

    if (changes.status && changes.status.currentValue === 'VALIDADO')
      this.motivo = '';
  }

  emitAction(a) {
    this.fileManagerEvent.emit({
      index: this.index,
      row: {
        status: this.status,
        fileInfo: this.fileInfo,
        actions: this.actions,
      },
      action: a,
      msjMotivo: this.motivo,
    });
  }

  fileChangeEvent(e: any) {
    if (e[0] == undefined) return;

    this.fileManagerEvent.emit({
      index: this.index,
      row: {
        status: this.status,
        fileInfo: this.fileInfo,
        actions: this.actions,
        input: this.input,
        input2: this.input2,
      },
      action: 'subir',
      data: e[0],
    });
  }
}

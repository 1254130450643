<div class="">
  {{ title }}
</div>
<div *ngFor="let row of rows; let i = index">
  <div class="card" id="{{ 'card-' + i }}">
    <div *ngIf="headerKey" class="card-header flex-container">
      <div>
        <i
          *ngIf="stateType == 'monitor'"
          [ngClass]="{
            'text-yellow': row.semaforo === 'amarillo',
            'text-red': row.semaforo === 'rojo',
            'text-green': row.semaforo === 'verde'
          }"
          class="fas fa-circle mr-1"
        ></i>
        <i
          *ngIf="stateType == 'alianza'"
          [ngClass]="{
            'text-yellow': row.status_asignado === 2,
            'text-red': row.status_asignado === 3,
            'text-blue': row.status_asignado === 1
          }"
          class="fas fa-circle mr-1"
        ></i>
        <label>{{ row[headerKey] }}</label>
      </div>            
      <div>
        <label style="float: right">Estado</label>
      </div>
    </div>
    <div (click)="defaultAction(row)" class="card-body">
      <div
        *ngFor="let col of tableHeaders; let j = index"
        [class]="'col-card-' + j"
        class="col"
      >
        <p class="cell-content">{{ row[col.name] }}</p>
        <p>{{ col.label }}</p>
      </div>
    </div>
    <div *ngFor="let colmn of actionColumns" class="card-footer">
      <div
        *ngIf="colmn.type === 'acceptance'"
        style="
          display: flex;
          text-align: center;
          justify-content: space-between;
          width: 100%;
          padding: 0px 20px;
        "
      >
        <div
          *ngFor="let value of colmn.values; let i = index"
          [ngStyle]="{
            display: row[colmn.key] == value.value ? 'block' : 'none'
          }"
          style="width: 100%; text-align: center"
        >        
          <label *ngIf="row[colmn.key] == value.value && stateType != 'alianza'">{{ colmn.name }}:</label
          >          
          &nbsp;
          <label *ngIf="row[colmn.key] == value.value" [class]="value.style">{{
            value.label
          }}</label>
        </div>
        <!--        <label *ngIf="row[colmn.key]==(colmn.values?colmn.values[0]:colmn.titles[0])">{{colmn.name}}: <span-->
        <!--          class="text-blue">{{colmn.values ? colmn.values[0] : colmn.titles[0]}}</span> </label>-->
        <!--        <label *ngIf="row[colmn.key]==(colmn.values?colmn.values[1]:colmn.titles[1])">{{colmn.name}}: <span-->
        <!--          class="text-red">{{colmn.values ? colmn.values[1] : colmn.titles[1]}}</span> </label>-->
        <!--        <label-->
        <!--          *ngIf="row[colmn.key]!=colmn.default && row[colmn.key]!=(colmn.values?colmn.values[1]:colmn.titles[1]) && row[colmn.key]!=(colmn.values?colmn.values[1]:colmn.titles[1])">-->
        <!--          {{colmn.name}}-->
        <!--          : <span-->
        <!--            class="text-red">{{row[colmn.key]}}</span> </label>-->
        <!-- -->
        <div
          *ngIf="
            row[colmn.key] == colmn.default ||
            (colmn.buttonOnNull && row[colmn.key] == null)
          "  
          [ngStyle]="{
            'justify-content':
              colmn.buttons.length > 1 ? 'space-between' : 'space-around'
          }"         
          style="width: 100%; text-align: center; display: flex"
        >
          <button
            (click)="actionEmit(button.action, row)"
            *ngFor="let button of colmn.buttons"
            [class]="button.style"
          >
            {{ button.label }}
            <!--            <i *ngIf="button.icon" [class]="button.icon"></i>-->
          </button>
        </div>
        <!--          <button style="float: left" (click)="actionEmit(colmn.actions[0],row)" *ngIf="row[colmn.key]==null" class="btn-option text-blue">-->
        <!--            {{colmn.titles[0]}}-->
        <!--          </button>-->
        <!--          <button style="float: right" (click)="actionEmit(colmn.actions[1],row)" *ngIf="row[colmn.key]==null" class="btn-option  text-red">-->
        <!--            {{colmn.titles[1]}}-->
        <!--          </button>-->
      </div>
      <div *ngIf="colmn.type === 'Accept'">
        <button
          (click)="actionEmit(colmn.action, row)"
          *ngIf="colmn.condition == null ? true : colmn.condition(row)"
          [class]="colmn.style"
        >
          {{ colmn.title }}
        </button>
      </div>
    </div>
    <div *ngIf="actions && actions.length > 1" class="card-actions"></div>
  </div>
</div>
<div></div>
<div *ngIf="rows?.lenght > 0" class="more">
  <small>Ver más</small>
</div>

<div class="main-container">
  <div *ngIf="!noDataError">
    <div *ngIf="type != 'quirurgico'">
      <div class="mb-4" *ngIf="rows?.length > 0">
        {{ title }}
      </div>
      <div
        *ngFor="
          let row of rows
            | paginate
              : {
                  id: this.id,
                  itemsPerPage: 10,
                  currentPage: p,
                  totalItems: totalRows
                };
          let i = index
        "
        (click)="clicked(i)"
      >
        <div class="card" style="margin: 10px 0px" id="{{ 'card-' + i }}">
          <div class="card-body">
            <div
              *ngFor="let col of tableHeaders; let j = index"
              class="col"
              [class]="'col-card-' + j"
            >
              <div *ngIf="type != 'pagos'">
                <p class="cell-content">{{ row[col.name] }}</p>
                <p>{{ col.label }}</p>
              </div>
              <div *ngIf="type == 'pagos'">
                <p class="cell-content">{{ col.label }}</p>
                <p>{{ row[col.name] }}</p>
              </div>
            </div>
          </div>

          <div class="triangle" *ngIf="type == 'agente'"></div>
        </div>
      </div>
    </div>

    <div style="text-align: center" *ngIf="!serverSidePagination">
      <pagination-controls
        class="my-pagination"
        (pageChange)="p = $event"
        previousLabel="Anterior"
        nextLabel="Siguiente"
        [id]="id"
      ></pagination-controls>
    </div>

    <div style="text-align: center" *ngIf="serverSidePagination">
      <pagination-controls
        class="my-pagination"
        (pageChange)="setPage($event)"
        previousLabel="Anterior"
        nextLabel="Siguiente"
        [id]="id"
      ></pagination-controls>
    </div>

    <div *ngIf="type == 'quirurgico'" class="quirurgico">
      <div class="mb-4" *ngIf="rows?.length > 0">
        {{ title }}
      </div>
      <div *ngFor="let row of rows; let i = index">
        <div class="card" style="margin: 10px 0px" id="{{ 'card-' + i }}">
          <div class="card-body">
            <div
              *ngFor="let col of tableHeaders; let j = index"
              class="col"
              [class]="'col-card-' + j"
            >
              <p class="cell-content">{{ col.label }}</p>
              <p>{{ row[col.name] }}</p>
            </div>
          </div>
        </div>
      </div>

      <div style="text-align: center">
        <pagination-controls
          class="my-pagination"
          (pageChange)="p = $event"
          previousLabel="Anterior"
          nextLabel="Siguiente"
        ></pagination-controls>
      </div>
    </div>
  </div>
  <div
    *ngIf="noDataError"
    class="flex-container"
    class="flex-container bg-danger text-danger pr-5 pl-5 mt-3"
  >
    <div style="margin-left: -10px">
      <i class="fas fa-exclamation-circle"></i>
      <label style="margin: 0 10px"
        >No se han obtenido resultados de esta búsqueda</label
      >
    </div>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiBienestarService } from 'projects/bienestar/src/app/service/api-bienestar.service';

@Component({
  selector: 'app-tramite-child',
  templateUrl: './tramite-child.component.html',
  styleUrls: ['./tramite-child.component.scss'],
})
export class TramiteChildComponent implements OnInit {
  backgroundImage: string;
  state: number = 0;
  arrayTest: Array<number>;
  headers: Array<string>;
  dataTimeLine: Array<any>;
  typeTimeLine: Array<string>;
  actualizado: string;
  datosPortal: any;
  canRender: boolean = false;

  user: any;
  @Input() idTramite: any;
  @Input() folioInput: any;
  @Input() module: any;

  type = 'reembolso';
  status = { info: null, fecha: 'dd/mm/yy', hora: 'hh:mm:ss', color: null };
  header = [
    'Tipo de Trámite',
    'Número de Folio/Siniestro',
    'Fecha de Solicitud',
  ];
  data = [];

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private _apiBcService: ApiBienestarService
  ) {
    this.actualizado = '03/03/2021';
    this.dataTimeLine = [
      '04/04/2021',
      'DD/MM/YY',
      'DD/MM/YY',
      'Devuelto Administrativamente',
    ];
    this.typeTimeLine = ['info', 'warning', 'danger'];

    this.arrayTest = [0, 1, 2, 3, 4];
    this.backgroundImage = 'assets/img/fondo-auth.jpeg';
    this.headers = [
      'Tipo de Trámite',
      'Número de Folio',
      'Número de Siniestro',
    ];
  }

  ngOnInit(): void {
    // this._apiBcService.getPortal(this.idTramite).subscribe((response) => {
    //   console.log(response);
    //   this.datosPortal = response;
    //   this.data = [
    //     response.grupo_tramite,
    //     response.nmsiniestro,
    //     response.feocurre,
    //   ];

    //   this.status.info = response.status ? response.status : 'N/A';

    //   this.dataTimeLine = [
    //     response.fecha,
    //     'DD/MM/YY',
    //     'DD/MM/YY',
    //     'Devuelto Administrativamente',
    //   ];
    //   this.state = 1;
    // });

    this._apiBcService.getPortalPrivado(this.idTramite, this.module).subscribe(
      (response) => {
        console.log(response);
        this.datosPortal = response;
        this.data = [
          response.tipoTramite,
          response.nmsiniestro,
          response.fechaSolicitud,
          response.nroFolio,
        ];

        this.status.info = response.status ? response.status : 'N/A';

        let auxInfo;
        if (response.statusNombre.length > 14) {
          auxInfo = response.statusNombre.substring(0, 15) + '...';
        } else {
          auxInfo = response.statusNombre;
        }

        this.status = {
          info: auxInfo,
          fecha: response.statusActualizado.split(' ')[0],
          hora:
            response.statusActualizado.split(' ')[1] +
            ' ' +
            response.statusActualizado.split(' ')[2],
          color: response.statusColor,
        };

        this.dataTimeLine = response.historial;
        console.log(this.dataTimeLine);

        this.state = 1;
      },
      (error) => {
        this.canRender = true;
      },
      () => {
        this.canRender = true;
      }
    );

    this.user = this._apiBcService.getCurrentUser();
  }
}

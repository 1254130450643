import { TableCardComponent } from './tables/table-card/table-card.component';
import { CirclePercentageComponent } from './circle-percentage/circle-percentage.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableMultiselectComponent } from './tables/table-multiselect/table-multiselect.component';
import { TableBasicComponent } from './tables/table-basic/table-basic.component';
import { TableSingleselectComponent } from './tables/table-singleselect/table-singleselect.component';
import { StepsBarComponent } from './steps-bar/steps-bar.component';
import { TimeLineComponent } from './time-line/time-line.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { AlertComponent } from './modals/alert/alert.component';
import { StateBarComponent } from './state-bar/state-bar.component';
import { SimpleCardComponent } from './simple-card/simple-card.component';
import { TableCardBasicComponent } from './tables/table-card-basic/table-card-basic.component';
import { FileDragNdropComponent } from './file-drag-ndrop/file-drag-ndrop.component';
import { AvisoPrivacidadComponent } from './aviso-privacidad/aviso-privacidad.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { CardButtonComponent } from './card-button/card-button.component';
import { ActionCardComponent } from './action-card/action-card.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { DragNdropFacturaComponent } from './drag-ndrop-factura/drag-ndrop-factura.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { NgxDatePickerModule } from '@ngx-tiny/date-picker';
import { DragNdropDocsComponent } from './drag-ndrop-docs/drag-ndrop-docs.component';
import { TableCardActionsComponent } from './tables/table-card-actions/table-card-actions.component';
import { TabsComponent } from './tabs/tabs.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { RouterModule } from '@angular/router';
import { DragNdropMultiComponent } from './drag-ndrop-multi/drag-ndrop-multi.component';
import { TramiteChildComponent } from './tramite-child/tramite-child.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PolizaCardComponent } from './poliza-card/poliza-card.component';
import { TableBasicActionsComponent } from './tables/table-basic-actions/table-basic-actions.component';
import { TableCardBasicActionsComponent } from './tables/table-card-basic-actions/table-card-basic-actions.component';

import { TabComponent } from './tabs-container/tab/tab.component';
import { TabsContainerComponent } from './tabs-container/tabs-container.component';
import { GenericCardComponent } from './cards/generic-card/generic-card.component';

import { NavbarBlueComponent } from './navbar-blue/navbar-blue.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { BreadcrumbsGlobalComponent } from './breadcrumbs-global/breadcrumbs-global.component';
import { PlantillaComponent } from './plantilla/plantilla.component';
import { TablePrintComponent } from './tables/table-print/table-print.component';
import { InputFileComponent } from './input-file/input-file.component';
import { FooterSuraComponent } from './footer-sura/footer-sura.component';
import { TableFacturasComponent } from './tables/table-facturas/table-facturas.component';
import { PreviewPdfComponent } from './preview-pdf/preview-pdf.component';
import { StepperComponent } from './stepper/stepper.component';
import { RelationSelectsComponent } from './relation-selects/relation-selects.component';
import { BoxFacturaSeComponent } from './box-factura-se/box-factura-se.component';
import { ContainerMessageComponent } from './container-message/container-message.component';

@NgModule({
  declarations: [
    ButtonComponent,
    TableBasicComponent,
    TableSingleselectComponent,
    TableMultiselectComponent,
    StepsBarComponent,
    TimeLineComponent,
    CirclePercentageComponent,
    AlertComponent,
    TableCardComponent,
    StateBarComponent,
    StepsBarComponent,
    SimpleCardComponent,
    TableCardBasicComponent,
    CardButtonComponent,
    ActionCardComponent,
    TableCardBasicComponent,
    FileDragNdropComponent,
    AvisoPrivacidadComponent,
    FileUploaderComponent,
    ProductCardComponent,
    DragNdropFacturaComponent,
    DatePickerComponent,
    DragNdropDocsComponent,
    TableCardActionsComponent,
    TabsComponent,
    BreadcrumbsComponent,
    DragNdropMultiComponent,
    TramiteChildComponent,
    PolizaCardComponent,
    TableBasicActionsComponent,
    TableCardBasicActionsComponent,
    TabsContainerComponent,
    TabComponent,
    GenericCardComponent,
    NavbarBlueComponent,
    HeaderComponent,
    FooterComponent,
    FileManagerComponent,
    BreadcrumbsGlobalComponent,
    PlantillaComponent,
    TablePrintComponent,
    InputFileComponent,
    FooterSuraComponent,
    TableFacturasComponent,
    PreviewPdfComponent,
    StepperComponent,
    RelationSelectsComponent,
    BoxFacturaSeComponent,
    ContainerMessageComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatePickerModule,
    NgxDatatableModule.forRoot({
      messages: {
        emptyMessage: 'No hay datos para mostrar', // Message to show when array is presented, but contains no values
        totalMessage: 'Total', // Footer total message
        selectedMessage: 'Seleccionados', // Footer selected message
      },
    }),
    NgxSmartModalModule.forRoot(),
    NgxFileDropModule,
    NgCircleProgressModule.forRoot({
      backgroundStrokeWidth: 0,
      radius: 60,
      space: -4,
      maxPercent: 100,
      outerStrokeWidth: 5,
      outerStrokeColor: '#e3e829',
      outerStrokeGradientStopColor: '#B9B9B9',
      outerStrokeLinecap: 'butt',
      innerStrokeColor: '#e7e8ea',
      innerStrokeWidth: 3,
      title: 'UI',
      imageHeight: 112,
      animateTitle: false,
      animationDuration: 1000,
      showUnits: false,
      showBackground: false,
      clockwise: false,
      startFromZero: false,
      lazy: true,
      titleFontSize: '30',
      subtitleFontSize: '19',
      titleColor: '#0033A0',
      titleFontWeight: '700',
      subtitle: 'Porcentaje',
    }),
    RouterModule,
    NgxPaginationModule,
  ],
  exports: [
    ButtonComponent,
    TableMultiselectComponent,
    TableBasicComponent,
    TableSingleselectComponent,
    CirclePercentageComponent,
    AlertComponent,
    StepsBarComponent,
    TableCardComponent,
    SimpleCardComponent,
    StateBarComponent,
    TableBasicComponent,
    TableCardBasicComponent,
    FileDragNdropComponent,
    AvisoPrivacidadComponent,
    CardButtonComponent,
    ActionCardComponent,
    TimeLineComponent,
    FileUploaderComponent,
    ProductCardComponent,
    DragNdropFacturaComponent,
    DragNdropDocsComponent,
    DatePickerComponent,
    TableCardActionsComponent,
    TabsComponent,
    BreadcrumbsComponent,
    DragNdropMultiComponent,
    TramiteChildComponent,
    PolizaCardComponent,
    TableBasicActionsComponent,
    TableCardBasicActionsComponent,
    TabsContainerComponent,
    TabComponent,
    GenericCardComponent,
    NavbarBlueComponent,
    HeaderComponent,
    FooterComponent,
    FileManagerComponent,
    BreadcrumbsGlobalComponent,
    PlantillaComponent,
    TablePrintComponent,
    InputFileComponent,
    FooterSuraComponent,
    TableFacturasComponent,
    StepperComponent,
    RelationSelectsComponent,
    BoxFacturaSeComponent,
    ContainerMessageComponent,
  ],
})
export class SharedModule {}

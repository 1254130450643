import { GestionProveedorService } from './gestion-proveedor/services/gestion-proveedor.service';
import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './services/api.service';
import { AppConstants } from 'src/app/constants';
import { GlobalService } from './services/global.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'alianza';
  userLogged: boolean = false;
  user: any;
  menu = [];

  constructor(
    public apiService: ApiService,
    private router: Router,
    public gp: GestionProveedorService,
    public global: GlobalService,
    private cdRef: ChangeDetectorRef
  ) {
    this.apiService.loginURL = AppConstants.loginURLAlianza;
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    let url_string = window.location.href;
    let url = new URL(url_string);
    let tk = url.searchParams.get('tk');
    if (tk != undefined && tk != null) {
      console.log(tk);
      this.apiService.verifyToken(tk).subscribe(
        (response) => {
          this.apiService
            .getUserInfo('Bearer ' + response.access_token)
            .subscribe(
              (response2) => {
                console.log('Inicio', response2);
                this.user = response2.data;
                this.apiService.setUserInfo(response2.data);
                this.apiService.setUser(response);
                this.apiService.setToken(response.access_token);
                this.userLogged = true;
                this.router.navigate(['home']);
              },
              (error) => {},
              () => {}
            );
        },
        (error) => {
          window.location.href = this.apiService.loginURL;
        },
        () => {}
      );
    } else {
      let url = window.location.href.includes('portal-seguimiento');

      if (!url) {
        if (!this.apiService.getCurrentUser())
          window.location.href = this.apiService.loginURL;
        else {
          this.userLogged = true;
          this.user = this.apiService.getCurrentUserInfo();
          this.router.navigate(['/home']);
        }
      }
      if (url) {
        this.userLogged = true;
      }
    }
  }
}

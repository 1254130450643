<div class="card" style="margin: 10px 0px;" id="{{ 'card-' + i }}">
  <input
    [checked]="deselectAll"
    class="card-selector"
    type="radio"
    name="{{ name }}"
    id="{{ 'radio-' + i }}"
  /><label for="{{ 'radio-' + i }}"></label>
  <div class="card-body">
    <div class="col1" style="padding-right: 30px;">
      <p class="cell-content">{{ title }}</p>
      <p>{{ text }}</p>
    </div>
  </div>
</div>

export class Proveedor {
  constructor(
    public agregarRed: boolean,
    public apellido1: string,
    public apellido2: string,
    public authId: string,
    public catalogoUrl: string,
    public cdperson: number,
    public ciudad: string,
    public codigo_postal: string,
    public colonia: string,
    public compDomUrl: string,
    public contratoFecha: string,
    public contratos: Array<any>,
    public domicilio: string,
    public documentosProveedor: Array<any>,
    public edoCuentaUrl: string,
    public email: string,
    public emailVerify: boolean,
    public fechaNacConst: string,
    public fechaActualizacion: string,
    public grupo: string,
    public historial: any,
    public id: number,
    public indexForm: number,
    public ineUrl: string,
    public municipio: string,
    public nacionalidad: string,
    public nexterior: string,
    public ninterior: string,
    public nombre1: string,
    public nombre2: string,
    public nombreComercial: string,
    public ocupacion: any,
    public pais: string,
    public provincia: string,
    public razonSocial: string,
    public reprRfc: string,
    public reprSexo: string,
    public reprApellido1: string,
    public reprApellido2: string,
    public reprfechaNac: string,
    public reprNombre1: string,
    public reprNombre2: string,
    public rfc: string,
    public sexo: string,
    public siglas: string,
    public status: any,
    public telefono1: string,
    public telefono2: string,
    public tipoPersona: number,
    public tipoProveedor: number,
    public valuesVerify: number,
    public datosComerciales: Array<any>,
    public cuentasBancarias: Array<any>,
    public latitud: any,
    public longitud: any,
    public reprNacionalidad: any
  ) {}
}

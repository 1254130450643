import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
  @Input() type: any;
  @Input() product: any;

  pct: any;

  ngOnInit(): void {
    // console.log(this.product);

    let pct;

    if (this.product)
      if (
        this.product.detalle.dias_restante &&
        this.product.detalle.dias_totales
      )
        pct =
          (this.product.detalle.dias_restante /
            this.product.detalle.dias_totales) *
          100;
      else pct = 0;

    this.pct = 'width: ' + '' + pct + '%';

    if (pct === 0) {
      this.pct = 'width: ' + '' + '100' + '%; background-color: #e4002b;';
    }
  }
}

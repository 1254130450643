<header class="header">
  <div class="logo" routerLink="/home">
    <img src="assets/img/LogoSURA.svg" alt="" width="130" />
  </div>

  <h3 class="text-secondary">{{ title }}</h3>

  <div class="user menu-item">
    <img alt="" src="assets/img/user.png" />

    <div class="submenu text-small text-body">
      <!-- <div class="submenu-item">Mi perfil</div> -->
      <!-- <div class="submenu-item" *ngIf="switchRol">
        <p>Cambiar de rol</p>
        <div class="submenu2 text-small text-body">
          <div class="submenu-item">Asegurado</div>
          <div class="submenu-item">Beneficiario</div>
          <div class="submenu-item">Afectado</div>
        </div>
      </div> -->
      <div class="submenu-item" (click)="singOut()">Cerrar sesión</div>
    </div>
  </div>
</header>

<div class="main-container">
  <div *ngIf="!noDataError">
    <div>
      <div class="mb-4" *ngIf="rows?.length > 0">
        {{ title }}
      </div>
      <div
        *ngFor="
          let row of rows
            | paginate
              : { itemsPerPage: 10, currentPage: p, totalItems: totalRows };
          let i = index
        "
        (click)="clicked(i)"
      >
        <div class="card" style="margin: 10px 0px" id="{{ 'card-' + i }}">
          <div class="card-body">
            <div
              *ngFor="let col of tableHeaders; let j = index"
              class="col"
              [class]="'col-card-' + j"
            >
              <p class="cell-content">{{ row[col.name] }}</p>
              <p>{{ col.label }}</p>
            </div>
          </div>

          <div class="actions-container" *ngIf="hasManyActions">
            <button class="btn-action btn-ellipsis" (click)="openOverlay(card)">
              <i class="fas fa-ellipsis-h"></i>
            </button>
          </div>

          <div class="overlay-actions hide" #card>
            <div
              style="text-align: right; padding: 10px; cursor: pointer"
              class="text-secondary"
              (click)="closeOverlay(card)"
            >
              <i class="fas fa-times close-btn"></i>
            </div>

            <div class="buttons">
              <i
                *ngFor="let action of row.actions"
                [class]="action.icon"
                [style]="'color: ' + action.color + ';'"
                [title]="action.label"
                (click)="emitActionColumn(action)"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="text-align: center" *ngIf="!serverSidePagination">
      <pagination-controls
        class="my-pagination"
        (pageChange)="p = $event"
        previousLabel="Anterior"
        nextLabel="Siguiente"
      ></pagination-controls>
    </div>

    <div style="text-align: center" *ngIf="serverSidePagination">
      <pagination-controls
        class="my-pagination"
        (pageChange)="setPage($event)"
        previousLabel="Anterior"
        nextLabel="Siguiente"
      ></pagination-controls>
    </div>
  </div>
  <div
    *ngIf="noDataError"
    class="flex-container"
    class="flex-container bg-danger text-danger pr-5 pl-5 mt-3"
  >
    <div style="margin-left: -10px">
      <i class="fas fa-exclamation-circle"></i>
      <label style="margin: 0 10px"
        >No se han obtenido resultados de esta búsqueda</label
      >
    </div>
  </div>
</div>

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-table-card-basic',
  templateUrl: './table-card-basic.component.html',
  styleUrls: ['./table-card-basic.component.scss'],
})
export class TableCardBasicComponent implements OnInit {
  @Input() tableHeaders: any;
  @Input() rows: any;
  @Input() name: any;
  @Input() title: any;
  @Input() type: any;
  p: any;
  @Input() isSearching: any;
  @Input() noDataError: any;
  @Input() id: any;

  @Input() serverSidePagination: boolean;
  @Input() totalRows;
  @Output() setPageEvent = new EventEmitter();

  @Output() action = new EventEmitter();

  constructor() {
    this.p = 1;
  }

  ngOnInit(): void {}

  clicked(i) {
    const index = (this.p - 1) * 5 + i;

    if (this.type === 'tramites' || this.type === 'agente') {
      this.action.emit({
        name: 'detail',
        data: this.rows[index],
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isSearching) {
      this.p = 1;
    }
  }

  setPage(e) {
    this.p = e;
    this.setPageEvent.emit({ offset: e - 1 });
  }
}

<ngx-file-drop
  dropZoneLabel="Drop files here"
  (onFileDrop)="dropped($event)"
  (onFileLeave)="fileLeave($event)"
  class="dnd-factura"
>
  <ng-template
    ngx-file-drop-content-tmp
    let-openFileSelector="openFileSelector"
  >
    <div class="center-container">
      <div class="buttons">
        <div class="action-button" (click)="openFileSelector()">
          <i class="fas fa-paperclip"></i>
          <p class="info">
            {{ info }}
          </p>
          <p class="text-bold">{{ format }}</p>
        </div>
      </div>
      <div class="title">
        {{ title }}
      </div>
    </div>
  </ng-template>
</ngx-file-drop>

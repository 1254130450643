import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit {
  file: any;
  @Output() fileEmitter = new EventEmitter<any>();
  @Output() templateEmitter = new EventEmitter<any>();
  @Input() index: any;
  @Input() item: any;
  @Input() status: any; //notLoaded, loaded, error, loading
  @Input() type: any;
  @Input() initURL: any;

  required: any;
  fileName: any;
  fileSize: any;

  constructor() {}

  ngOnInit(): void {
    this.fileName = 'Ejemplo de Nombre de Documento';
    this.fileSize = 'PDF - Max. 2Mb';

    this.fileName = this.item.nombre;
    this.fileSize = '';

    if (this.item.obligatorio) {
      this.required = 'Requerido';
    } else {
      this.required = 'Opcional';
    }

    if (this.initURL) {
      let lastIndex = this.initURL.lastIndexOf('/');
      let name = this.initURL.substring(lastIndex).replaceAll('/', '');

      if (name) {
        this.item.status = 'loaded';
        this.file = { name };
      }
    }
  }

  open(uploader) {
    uploader.click();
  }

  downloadPlantilla(data) {
    this.templateEmitter.emit(data);
  }

  receiveFile(emit: boolean) {
    let sizeInMB = (this.file.size / (1024 * 1024)).toFixed(2);
    this.fileSize = sizeInMB + 'Mb';
    this.status = 'loaded';
    this.fileName = this.file.name;
    if (emit) {
      this.fileEmitter.emit(this.file);
    }
  }

  fileChangeEvent(e: any) {
    if (e[0] == undefined) return;

    this.file = null;
    this.file = e[0];

    if (this.file) {
      let sizeInMB = (this.file.size / (1024 * 1024)).toFixed(2);
      this.fileSize = sizeInMB + 'Mb';

      console.log(this.status);

      if (this.status == 'loaded' || this.status == 'error')
        this.fileName = this.file.name;

      if (this.status == 'notLoaded') this.fileName = this.item.nombre;

      this.fileEmitter.emit({
        file: this.file,
        item: this.item,
        type: this.type,
        index: this.index,
      });
    }

    // this.validations();
  }

  validations() {
    this.status = 'error';
    this.fileName = 'El archivo Sample.pdf supera el tamaño permitido: 2Mb';
  }

  loadFile(uploader) {
    let w = window.innerWidth;

    if (w < 768) {
      uploader.click();
    }
  }
}

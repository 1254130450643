<div *ngIf="canRender">
  <div class="bar-container">
    <app-state-bar
      [type]="type"
      [header]="header"
      [data]="data"
      [status]="status"
    ></app-state-bar>
  </div>

  <div class="portal-container-part-2">
    <div *ngIf="datosPortal"></div>
    <div class="container-body" style="display: flex">
      <div class="time-line-container">
        <div *ngFor="let item of dataTimeLine; let i = index">
          <app-time-line
            [title]="item[0]"
            *ngIf="i + 1 == dataTimeLine.length"
            [last]="true"
          >
            <div class="text-secondary bg-info pl-5 mb-3">
              {{ item[1] }} - {{ item[2] }}
            </div>
          </app-time-line>

          <app-time-line [title]="item[0]" *ngIf="i + 1 != dataTimeLine.length">
            <div class="text-secondary bg-info pl-5 mb-3">
              {{ item[1] }} - {{ item[2] }}
            </div>
          </app-time-line>
        </div>
      </div>
      <div style="flex: 0.1"></div>
      <div class="sidebar" style="margin-top: 50px; padding: 30px 10px">
        <div class="link">
          <p href="">Actualizar información de contacto</p>
          <i class="fas fa-arrow-right ml-5"></i>
        </div>
        <div class="link">
          <p href="">Conozca el proceso de gestión de su trámite</p>
          <i class="fas fa-arrow-right ml-5"></i>
        </div>
        <div class="link">
          <p href="">Obtener asistencia de un asesor</p>
          <i class="fas fa-arrow-right ml-5"></i>
        </div>
        <div class="link">
          <p href="">Preguntas Frecuentes</p>
          <i class="fas fa-arrow-right ml-5"></i>
        </div>
        <div class="link">
          <p href="">Imprimir Reporte de Trámite</p>
          <i class="fas fa-arrow-right ml-5"></i>
        </div>
      </div>
    </div>
  </div>
</div>

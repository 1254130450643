import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-state-bar',
  templateUrl: './state-bar.component.html',
  styleUrls: ['./state-bar.component.scss'],
})
export class StateBarComponent implements OnInit {
  @Input() header: Array<string>;
  @Input() data: Array<string>;
  @Input() type: string;
  @Input() status: any;
  constructor() {}

  ngOnInit(): void {
    console.log(this.status);
  }
}

<!-- <global-header></global-header> -->
<header>
  <div class="logo">
    <img src="assets/img/LogoSURA.svg" alt="" width="130" />
  </div>
</header>

<div class="container">
  <div class="title mt-3">
    <p class="text-bold">SEGUROS</p>
  </div>

  <div class="section">
    <p>
      Este es un ejemplo de correo electrónico que puede enviar la plataforma de
      manera predefinida, para mejorar la imagen visual que se tiene desde la
      referencia de la <strong>imagen corporativa.</strong>
    </p>
    <br />
    <p>
      Se recomienda redactar los correos a manera de frases cortas y si se
      requieren acciones de parte del usuario, éstas deben presentarse a manera
      de listas enumeradas
    </p>
    <br />

    <p>1. &nbsp;Descripción del paso 1</p>
    <p>2. Descripción del paso 2</p>
    <p>3. Descripción del paso 3</p>

    <br />

    <p>
      Idealmente, si el correo tiene una acción clara esperada por parte del
      usuario, ésta debe aparecer a manera de botón
    </p>
    <br />
    <button class="button text-bold">Ejemplo de CTA</button>
  </div>
</div>

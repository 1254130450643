import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { getFormattedDate } from 'src/functions/common-functions';

@Component({
  selector: 'app-table-multiselect',
  templateUrl: './table-multiselect.component.html',
  styleUrls: ['./table-multiselect.component.scss'],
})
export class TableMultiselectComponent {
  @Input() tableHeaders: any;
  @Input() isSearching: any;
  @Input() clearTable: any;
  @Input() name: any;
  @Input() noDataError: any;
  @Input() noData: boolean;
  @Input() msjError: any;
  @Output() setPageEvent: EventEmitter<any>;
  @Input() serverSidePagination: boolean;
  @Input() totalRows;
  offset = 0;
  @Input() resetOffset;
  _rows: any;

  @Input()
  set rows(val: any) {
    if (val != undefined) {
      this._rows = val;
      this.temp = val;

      this.transformDates(this._rows);
      this.transformDates(this.temp);
    }
  }

  @Input()
  set setSelected(arrayVal: any) {
    if (arrayVal && arrayVal.length > 0) {
      this.selected = [];
      for (let index = 0; index < arrayVal.length; index++) {
        const val = arrayVal[index];

        if (val != undefined && val != null) {
          this.selected.push(this._rows[val]);
        }
      }
    }
  }

  @Output() actionSelected = new EventEmitter<any>();

  firstLoad: boolean = false;

  temp: Array<any> = [];
  selected: Array<any> = [];

  entriesVal: any;
  entries: Array<any> = [];

  ColumnMode = ColumnMode;
  SelectionType = SelectionType;

  constructor(private datePipe: DatePipe) {
    this.entries = [5, 10, 15];
    this.entriesVal = 5;
    this.selected = [];
    this.setPageEvent = new EventEmitter();
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isSearching) {
      this.resetTable();
    }

    if (changes.clearTable?.currentValue) {
      this.resetTable();
    }

    if (changes.resetOffset) this.offset = 0;
  }

  onSelect({ selected }: any) {
    // console.log('Select Event', selected, this.selected);

    let tempSelected = JSON.parse(JSON.stringify(this.selected));
    this.transformDatesReverse(tempSelected);
    this.actionSelected.emit(tempSelected);
  }

  onActivate(event: any) {
    // console.log('Activate Event', event);
  }

  checkSelectable(event: any) {
    // console.log('Checking if selectable', event);
    return event.name !== 'Claudine Neal';
  }

  resetTable() {
    this.selected = [];
  }

  transformDates(rows) {
    const regexYYYYmmDD = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/;

    for (let index = 0; index < rows.length; index++) {
      let element = rows[index];

      for (let key in element) {
        const item = element[key];

        if (item && item.toString().match(regexYYYYmmDD)) {
          element[key] = this.datePipe.transform(item, 'dd/MM/yyyy');
        }
      }
    }
  }

  transformDatesReverse(rows) {
    const regexDDmmYYYY =
      /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

    for (let index = 0; index < rows.length; index++) {
      let element = rows[index];

      for (let key in element) {
        const item = element[key];

        if (item && item.toString().match(regexDDmmYYYY)) {
          element[key] = getFormattedDate(item, 'yyyy-mm-dd');
        }
      }
    }
  }
  setPage(e) {
    this.offset = e.offset;
    this.setPageEvent.emit(e);
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FileSystemDirectoryEntry,
  FileSystemFileEntry,
  NgxFileDropEntry,
} from 'ngx-file-drop';

import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-file-drag-ndrop',
  templateUrl: './file-drag-ndrop.component.html',
  styleUrls: ['./file-drag-ndrop.component.scss'],
})
export class FileDragNdropComponent implements OnInit {
  //load, load-download, loaded
  @Input() type: any;
  @Input() block:boolean
  @Input() title: any;
  @Input() condition: any;
  @Input() required: any;
  @Input() initFile: any;
  @Input() initURL: any;
  @Input() initDate: any
  @Input() format:any
  @Input() dateClear:boolean
  @Output() loaded = new EventEmitter<any>();
  @Output() downloaded = new EventEmitter<any>();
  @Output() deleted = new EventEmitter<any>();
  @Output() dateSelected = new EventEmitter<any>();
  // @Input() file: any;

  file: any = {
    name: '',
    size: '',
  };

  dateString: any;
  today: any;

  public files: NgxFileDropEntry[] = [];

  xFile: any;
  urlFile: any;

  constructor(private cdRef: ChangeDetectorRef) {
    this.type = 'load';
    this.today = new Date();
  }

  ngOnInit(): void {
    
    
    let file;
    if (this.initFile) {
      file = this.initFile.file;
      this.xFile = this.initFile.file;
    }

    if (file) {
      var sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
      this.file.name = file.name;
      this.file.size = sizeInMB;
    }

    if (this.initURL) {
      let lastIndex = this.initURL.lastIndexOf('/');
      let name = this.initURL.substring(lastIndex).replaceAll('/', '');
      this.initFile = 1;

      if (name) {
        this.file.name = name;
        this.file.size = null;
      }
    }    
    if(this.initDate){
       this.dateString = this.initDate      
    }
  }

  ngOnChanges(changes: SimpleChanges): void {        
    
  if(changes.initDate){
    if(changes.initDate.currentValue == ''){
      this.dateString = ''
      this.file.name = '';
        this.file.size = '';
    }else{
      this.ngOnInit()
    }
  }    

    if (changes.initFile) {
      if (changes.initFile.currentValue == 0 || changes.initFile.currentValue == null ) {
        this.file.name = '';
        this.file.size = '';
      } else {
        this.ngOnInit();
      }
    }

    if (changes.initURL) {
      if (changes.initURL.currentValue == null) {
        this.file.name = '';
        this.file.size = '';
      } else {
        this.ngOnInit();
      }
    }
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();

    if (this.initFile == 0) {
      this.file.name = '';
      this.file.size = '';
    }
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;

    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.xFile = file;

          this.loaded.emit({
            name: this.title,
            file: this.xFile,
          });

          var sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
          this.file.name = file.name;
          this.file.size = sizeInMB;
        });
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  fileLeave(e: any) {
    // console.log(e);
  }

  saveFile() {
    // this.download('test.txt', 'Hello world!');
    this.download(this.file.name, this.file);
  }

  download(filename: any, text: any) {

    console.log(filename);
    console.log(text);
    
    var pom = document.createElement('a');
    pom.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
    );
    pom.setAttribute('download', filename);

    if (document.createEvent) {
      var event = document.createEvent('MouseEvents');
      event.initEvent('click', true, true);
      pom.dispatchEvent(event);
    } else {
      pom.click();
    }
  }

  watchFile() {
    this.urlFile = window.URL.createObjectURL(this.xFile);
    window.open(this.urlFile, '_blank');
  }

  downloadNew() {
    if (this.initURL) {      
      window.open(this.initURL, '_blank');
      return;
    }

    let dllink: any = document.getElementById('dllink');
    let url = window.URL.createObjectURL(this.xFile);
    dllink.setAttribute('href', url);
    dllink.setAttribute('download', this.xFile.name);
    dllink.click();
  }

  deleteFile() {
    this.files = null;
    this.xFile = null;
    this.initURL = null;
    this.initFile = 0;

    this.file = {
      name: '',
      size: '',
    };
    this.loaded.emit({
      name: this.title,
      file: null,
    });
  }

  downloadFile() {
    this.downloaded.emit(true);
  }

  validateDP(e) {
    this.dateSelected.emit(e);
    this.dateString = e.date;
  }
}

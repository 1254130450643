import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-time-line',
  templateUrl: './time-line.component.html',
  styleUrls: ['./time-line.component.scss'],
})
export class TimeLineComponent implements OnInit {
  @Input() last: boolean;
  @Input() title: string;
  @Input() color: string;
  constructor() {
    this.title = 'DD/MM/YY';
    this.last = false;
  }

  ngOnInit(): void {}
}

<div *ngIf="expand == false" class="container-card">
  <div class="type-info" *ngIf="!info.numero">
    <i [ngClass]="[info.icon, 'mr-2']"></i>
    <p class="text-medium text-uppercase">{{ info.text }}</p>
  </div>

  <div class="type-info2" *ngIf="info.numero">
    <div style="display: flex">
      <i [ngClass]="[info.icon, 'mr-2']"></i>
      <p class="text-medium text-uppercase">{{ info.text }}</p>
    </div>
    <p class="text-medium text-bold text-uppercase w-100 margin-special">
      {{ info.numero }}
    </p>
  </div>

  <div *ngIf="!anotaciones">
    <div class="collapse" (click)="test()">
      <i class="fas fa-minus"></i>
    </div>

    <div *ngIf="valor" class="position-count">
      <div class="section-count">
        <p class="text-bold">{{ valor }}</p>
      </div>
    </div>
  </div>

  <ng-content></ng-content>
</div>

<div *ngIf="expand == true" class="container-collapse">
  <div class="info-collapse">
    <i [ngClass]="[info.icon, 'mr-2']"></i>
    <p class="text-medium text-uppercase">{{ info.text }}</p>
  </div>
  <div class="collapse" (click)="test()">
    <i class="fas fa-expand-arrows-alt"></i>
  </div>

  <div *ngIf="valor" class="position-count">
    <div class="section-count">
      <p class="text-bold">{{ valor }}</p>
    </div>
  </div>
</div>

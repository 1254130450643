import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-action-card',
  templateUrl: './action-card.component.html',
  styleUrls: ['./action-card.component.scss'],
})
export class ActionCardComponent implements OnInit {
  public status: boolean;
  @Input() headers: Array<string>;
  @Input() beneficiario: any;
  @Input() isAsegurado: boolean;
  porcentaje: number;
  @Input() beneficiarioId: any;

  @Output() action = new EventEmitter<any>();
  // @Output() next: EventEmitter<any> = new EventEmitter();
  constructor() {
    this.status = false;
  }

  ngOnInit(): void {
    console.log(this.beneficiario);
  }
  activeOverlay() {
    this.status = !this.status;
  }
  editar(id) {
    console.log('quiero editar');

    this.action.emit({
      action: 'edit',
      id: id,
    });
  }
  eliminar(id) {
    this.action.emit({
      action: 'delete',
      id: id,
    });
  }
}

<div class="form-control file-box" *ngIf="!fileExist?.size">
  <input
    (change)="fileChangeEvent($event.target.files)"
    #uploader
    type="file"
    name="myImage"
  />
  {{ file?.name }}
  <i (click)="open(uploader)" class="fas fa-folder-open"></i>
</div>
<div class="form-control file-box" *ngIf="fileExist?.size">
  <input
    (change)="fileChangeEvent($event.target.files)"
    #uploader
    type="file"
    name="myImage"
  />
  {{ fileExist?.name }}
  <i (click)="open(uploader)" class="fas fa-folder-open"></i>
</div>

import { ApiService } from './../services/api.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GestionProveedorService } from '../gestion-proveedor/services/gestion-proveedor.service';
import { Proveedor } from '../gestion-proveedor/models/proveedor.model';
import { GlobalService } from '../services/global.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  waitingForApi: boolean;

  constructor(
    public apiService: ApiService,
    private gp: GestionProveedorService,
    private router: Router,
    public global: GlobalService
  ) {}

  ngOnInit(): void {
    // console.log('aqui va la logica');
    let user = this.apiService.getCurrentUser();

    let token = this.apiService.getUserToken();

    this.waitingForApi = true;

    this.apiService.getDataSupplier(token).subscribe(
      (resp: any) => {
        if (resp.tipoProveedor)
          localStorage.setItem('code_prov', resp.tipoProveedor.codigo);

        this.waitingForApi = false;
        this.gp.proveedor = new Proveedor(
          resp.agregarRed,
          resp.apellido1,
          resp.apellido2,
          resp.authId,
          resp.catalogoUrl,
          resp.cdperson,
          resp.ciudad,
          resp.codigoPostal,
          resp.colonia,
          resp.compDomUrl,
          resp.contratoFecha,
          resp.contratos,
          resp.domicilio,
          resp.documentosProveedor,
          resp.edoCuentaUrl,
          resp.email,
          resp.emailVerify,
          resp.fechaNacConst,
          resp.fechaActualizacion,
          resp.grupo,
          resp.historial,
          resp.id,
          resp.indexForm,
          resp.ineUrl,
          resp.municipio,
          resp.nacionalidad,
          resp.nexterior,
          resp.ninterior,
          resp.nombre1,
          resp.nombre2,
          resp.nombreComercial,
          resp.ocupacion ? resp.ocupacion.id : null,
          resp.pais,
          resp.provincia,
          resp.razonSocial,
          resp.reprRfc,
          resp.reprSexo,
          resp.reprApellido1,
          resp.reprApellido2,
          resp.reprfechaNac,
          resp.reprNombre1,
          resp.reprNombre2,
          resp.rfc,
          resp.sexo,
          resp.siglas,
          resp.status,
          resp.telefono1,
          resp.telefono2,
          resp.tipoPersona,
          resp.tipoProveedor ? resp.tipoProveedor.id : null,
          resp.valuesVerify,
          resp.datosComerciales,
          resp.cuentasBancarias,
          resp.latitud,
          resp.longitud,
          resp.reprNacionalidad
        );

        if (resp.indexForm == 8) {
          this.gp.actualStep = 7;
        }

        if (!resp.cdperson && resp.indexForm == 2) {
          this.gp.actualStep = 1;
          if (resp.cdperson) {
            this.gp.actualStep = 0;
          }
        }

        if (
          resp.valuesVerify == 2 ||
          resp.valuesVerify == 3 ||
          resp.valuesVerify == 4 ||
          resp.valuesVerify == 5
        ) {
          this.gp.actualStep = 7;
        }

        //Contrato rechazado
        if (resp.valuesVerify == 7) {
          this.gp.actualStep = 8;
        }

        //Contrato activo
        if (resp.valuesVerify == 6) {
          /**
           * Temporalmente permite para ver
           * las vistas por defecto debe ir /perfil-comercial*/
          this.router.navigate(['/dashboard/perfil-comercial']);
        } else {
          this.router.navigate(['/gestion-proveedor']);
        }

        /**
         * Valores que se usan para hacer pruebas
         */
        // this.gp.proveedor.valuesVerify = 1
        // this.gp.actualStep = 3 // DOCUMENTACION REQUERIDA
        // this.gp.actualStep = 6; //CUENTA BANCARIA
        // this.gp.proveedor.valuesVerify = 3
        // this.gp.actualStep = 8 // CONTRATO MARCO
      },
      (error) => {
        console.log(error);
      },
      () => {
        // this.router.navigate(['/dashboard']);
        // this.router.navigate(['/gestion-proveedor']);
        // this.waitingForApi = false;
        // setTimeout(() => {
        //   this.waitingForApi = false;
        //   console.log('asdasd');
        // }, 500);
      }
    );
  }
}

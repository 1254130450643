import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-table-print',
  templateUrl: './table-print.component.html',
  styleUrls: ['./table-print.component.scss'],
})
export class TablePrintComponent implements OnInit {
  _rows: any;
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  limit = 10;
  @Input() serverSidePagination: boolean;
  @Input() totalRows;
  @Input() tableHeaders: any;
  @Input() rows: any;
  @Input() unlimited;

  constructor() {}

  ngOnInit(): void {
    if (this.unlimited) this.limit = 10000;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rows) {
      this._rows = changes.rows.currentValue;
    }
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'footer-sura',
  templateUrl: './footer-sura.component.html',
  styleUrls: ['./footer-sura.component.scss'],
})
export class FooterSuraComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  goSocial(s) {
    switch (s) {
      case 'facebook':
        window.open('https://www.facebook.com/SegurosSURAMexico/', '_blank');
        break;
      case 'instagram':
        window.open('https://www.instagram.com/segurossuramx/', '_blank');
        break;
      case 'twitter':
        window.open('https://twitter.com/SegurosSURA_MX', '_blank');
        break;
      case 'youtube':
        window.open(
          'https://www.youtube.com/channel/UCXm_a8qOYWL4oYnzHcoeWlw',
          '_blank'
        );
        break;
      case 'linkedin':
        window.open(
          'https://www.linkedin.com/company/seguros-sura/?viewAsMember=true',
          '_blank'
        );
        break;
      case 'privacidad':
        window.open(
          'https://www.segurossura.com.mx/legales/aviso-de-privacidad/',
          '_blank'
        );
        break;
      default:
        break;
    }
  }
}

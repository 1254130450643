<header>
  <div class="logo">
    <img src="assets/img/LogoSURA.svg" alt="" width="130" />
  </div>
  <h3 class="text-secondary">
    {{ title ? title : "SISTEMA DE GESTIÓN DE PROVEEDORES" }}
  </h3>

  <div class="responsive-menu" id="responsive-menu">
    <span *ngIf="user?.preferred_username">{{ user.preferred_username }} </span>
    <img
      alt=""
      src="assets/img/user.png"
      (click)="switchDropdown()"
      style="width: 35px;"
    />

    <div class="dropdown" id="dropdown" [ngClass]="{ active: showDropdown }">
      <p (click)="singOut()">Cerrar Sesión</p>
    </div>

    <div class="burger-menu" (click)="switchDropdownBurger()">
      <i class="fas fa-bars"></i>
      <div
        class="dropdown"
        id="dropdownBurger"
        [ngClass]="{ active: showDropdownBurger }"
      >
        <p>Dashboard</p>
        <p>Mis Trámites</p>
        <p>Confirmación</p>
        <p>Reportes</p>
      </div>
    </div>
  </div>
</header>

<ngx-smart-modal
  #logoutModal
  identifier="logoutModal"
  [customClass]="'nsm-centered modal-logout'"
  class="text-center logoutModal"
>
  <div style="padding: 45px">
    <p>
      Aún no ha guardado los últimos cambios. Si cierra sesión, puede perder la información actualizada. ¿Desea continuar?
    </p>
  </div>
  <div>
    <button class="btn btn-primary btn-confirm" (click)="confirmLogout()">
      Si
    </button>
    <button class="btn btn-primary btn-confirm" (click)="closeModal()">
      No
    </button>
  </div>
  
</ngx-smart-modal>

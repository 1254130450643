<div class="preview-container">
  <iframe
    id="iframe"
    [src]="iframeURL"
    frameborder="0"
    allowfullscreen
    width="100%"
  >
  </iframe>
</div>

export const environment = {
  production: false,
  apiBienestar: "https://portalmonitor-qa.segurossura.com.mx/api-cliente",
  bienestarAuth: "https://portalclientes-qa.segurossura.com.mx/api-auth",
  bienestarURL: "http://portalclientes-qa.segurossura.com.mx",
  loginURL: "http://auth-front-priv-siniestros-clientes-qa.apps.u9n6ogij.southcentralus.aroapp.io",
  loginURLAlianza: "http://auth-front-priv-siniestros-clientes-qa.apps.u9n6ogij.southcentralus.aroapp.io/login-alianza",
  alianzasURL: "http://portalaliados-qa.segurossura.com.mx",
  keyCapchat: "6LdVfAAfAAAAAKfKHsEwrPOWZUQKyPsitb7tN_Bd",
  cypherSecretKey: "ceU7286f._,x:nXN",
};

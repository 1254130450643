import {
  FormGroup,
  FormBuilder,
  FormArray,
  FormControl,
  Validators,
} from '@angular/forms';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-relation-selects',
  templateUrl: './relation-selects.component.html',
  styleUrls: ['./relation-selects.component.scss'],
})
export class RelationSelectsComponent implements OnInit {
  @Input() arrayCatalogo: any;
  @Input() id: any;
  @Input() data;
  @Input() count;
  @Input() clear;
  @Input() previewDisabled;
  @Output() actions = new EventEmitter<any>();
  @Output() renglonValid = new EventEmitter<any>();
  form: FormGroup;

  countCatalogo = 0;
  disableButton: boolean;
  errorRepeat: string = null;
  dataCat: any[] = [];
  someVoid: boolean = false;

  constructor(public fb: FormBuilder) {
    this.form = this.fb.group({
      catalogos: this.fb.array([]),
    });
  }

  get f() {
    return this.form.controls;
  }

  get catalogo(): FormArray {
    return this.form.get('catalogos') as FormArray;
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    // console.log(changes);
    if (changes.previewDisabled)
      if (changes.previewDisabled.currentValue === 'true') {
        this.disableButton = true;
      }

    if (changes.clear === undefined) {
      // console.log(changes.clear);
      this.countCatalogo = 0;
      this.disableButton = false;
      this.errorRepeat = null;
      this.catalogo.clear();
      this.addCatalogo();
    } else {
      if (changes.clear.currentValue == true) {
        // console.log(changes.clear);
        this.countCatalogo = 0;
        this.disableButton = false;
        this.errorRepeat = null;
        this.catalogo.clear();
        this.addCatalogo();
      }
    }
  }

  ngOnInit(): void {
    this.form.valueChanges.subscribe((x) => {
      this.renglonValid.emit({
        form: this.form.valid,
        msjError: this.errorRepeat,
        id: this.id,
      });
    });

    // console.log(this.data);

    if (this.data[0].relaciones.length != 0) {
      this.initFormArray();
    } else {
      this.addCatalogo();
    }
  }

  //PRECARGAR DATA

  initFormArray() {
    this.catalogo.clear();
    var datos = [];

    this.data.forEach((element) => {
      datos.push({
        catalogos: Object.keys(element),
      });
    });

    // console.log(this.data);

    let result = this.removeDuplicates(this.data[0].relaciones, 'catalogos');

    // console.log(result.length);

    if (result.length > 3) {
      result.splice(0, 2);
    }

    // console.log(result);

    result.forEach((element) => {
      let catalogo = this.fb.group({
        catalogo: new FormControl(element.catalogos),
      });
      this.catalogo.push(catalogo);
    });

    // console.log(this.catalogo);

    this.count = this.catalogo.controls.length;

    if (this.count == this.arrayCatalogo.length) {
      this.disableButton = true;
    }

    this.countCatalogo = this.catalogo.controls.length;
  }

  removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  configRela() {
    this.catalogo.value.map((item, index) => {
      let value = this.arrayCatalogo.find(
        (x) => x.code === this.catalogo.value[index].catalogo
      );

      this.dataCat.push({
        catalogo: value,
      });
    });

    this.actions.emit({
      action: 'configurar',
      id: this.id,
      data: this.dataCat,
      tam: this.catalogo.length,
    });
  }

  removeRenglon() {
    this.actions.emit({
      action: 'eliminar',
      id: this.id,
      data: this.catalogo.value,
    });
  }

  addCatalogo() {
    const catalogo = this.fb.group({
      catalogo: new FormControl('', Validators.required),
    });

    this.catalogo.push(catalogo);
    this.countCatalogo++;
    if (this.form.value.catalogos.length > 1) {
      // console.log(this.catalogo.controls);
      this.catalogo.controls.forEach((element) => {
        // console.log(element.get('catalogo'));

        element.get('catalogo').setValidators([Validators.required]);
        element.get('catalogo').updateValueAndValidity();
      });
    }

    this.checkVoidCatalogo();

    if (this.countCatalogo == this.arrayCatalogo.length) {
      this.disableButton = true;
    }
  }

  checkVoidCatalogo() {
    this.someVoid = this.f.catalogos.value.some((x) => x.catalogo === '');
  }

  detectedCatalogo(e) {
    this.checkVoidCatalogo();

    this.count = 0;
    this.form.value.catalogos.forEach((element) => {
      if (element.catalogo === e) {
        this.count++;
      }
    });

    if (this.count > 1) {
      this.errorRepeat = 'Tiene catálogos duplicados, debe verificar';
    } else {
      this.errorRepeat = null;
    }

    var busqueda = this.form.value.catalogos.reduce((acc, data) => {
      if (data.catalogo !== '') {
        acc[data.catalogo] = ++acc[data.catalogo] || 0;
        return acc;
      }
    }, {});

    // console.log(busqueda);

    for (var key in busqueda) {
      const element = busqueda[key];

      if (element >= 1) {
        this.errorRepeat = 'Tiene catálogos duplicados, debe verificar';
        break;
      } else {
        this.errorRepeat = null;
      }
    }

    this.renglonValid.emit({
      form: this.form.valid,
      msjError: this.errorRepeat,
      id: this.id,
    });
  }
}

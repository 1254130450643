import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  @Input() options: Array<string>;
  activeOption: string;
  @Output() selected: EventEmitter<string>;
  indes = 0;

  constructor() {
    this.options = ['PRINCIPAL', 'REPORTES'];
    this.selected = new EventEmitter<string>();
  }

  ngOnInit(): void {
    this.activeOption = this.options[0];
    this.selected.emit(this.options[0]);
  }

  activeMenu(item) {
    console.log(item);
    this.activeOption = item;
    this.selected.emit(item);
  }

  next() {
    if (this.indes < this.options.length - 2) {
      this.indes++;
    }
  }

  prev() {
    if (this.indes > 0) {
      this.indes--;
    }
  }

}

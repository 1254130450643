import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'box-factura-se',
  templateUrl: './box-factura-se.component.html',
  styleUrls: ['./box-factura-se.component.scss'],
})
export class BoxFacturaSeComponent implements OnInit {
  @Input() factura: any;
  @Input() index: any;
  @Input() arrayAtenciones: any;

  @Output() selectFactura = new EventEmitter<any>();

  form: FormGroup;

  constructor(public fb: FormBuilder) {
    this.form = this.fb.group({
      atencion: [null, Validators.required],
    });
  }

  ngOnInit(): void {}

  get fBox() {
    return this.form.controls;
  }

  selectChange() {
    if (this.form.valid) {
      this.selectFactura.emit({
        index: this.index,
        tipoAtencion: this.form.value.atencion,
      });
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-header-alianza',
  templateUrl: './header-alianza.component.html',
  styleUrls: ['./header-alianza.component.scss'],
})
export class HeaderAlianzaComponent implements OnInit {
  @Input() title: string;
  showDropdown: boolean = false;
  showDropdownBurger: boolean = false;
  @Input() user: any;

  constructor(
    public apiService: ApiService,
    private router: Router,
    public ngxSmartModalService: NgxSmartModalService
  ) {}

  ngOnInit(): void {
    this.dropDownListener();
    this.dropDownListenerBurger();
  }

  logout() {
    window.location.href = 'http://loginfront.azurewebsites.net';
  }

  switchDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  switchDropdownBurger() {
    this.showDropdownBurger = !this.showDropdownBurger;
  }

  dropDownListener() {
    document.addEventListener('click', (evt) => {
      const dropdown = document.getElementById('dropdown');
      let targetElement: any = evt.target;

      if (targetElement.closest('.responsive-menu')) return;

      do {
        if (targetElement == dropdown) {
          return;
        }

        targetElement = targetElement.parentNode;
      } while (targetElement);

      // console.log("Clicked outside!");
      this.showDropdown = false;
    });
  }

  dropDownListenerBurger() {
    document.addEventListener('click', (evt) => {
      const dropdown = document.getElementById('dropdownBurger');
      let targetElement: any = evt.target;

      if (targetElement.closest('.burger-menu')) return;

      do {
        if (targetElement == dropdown) {
          return;
        }

        targetElement = targetElement.parentNode;
      } while (targetElement);

      // console.log("Clicked outside!");
      this.showDropdownBurger = false;
    });
  }

  singOut() {
    this.showDropdown = false;

    if (this.router.url.includes('gestion-proveedor')) {
      this.ngxSmartModalService.getModal('logoutModal').open();
    }else{
      this.confirmLogout();
    }
  }

  closeModal() {
    this.ngxSmartModalService.getModal('logoutModal').close();
  }

  confirmLogout() {
    this.apiService.logout().subscribe(
      (response) => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userToken');
        localStorage.removeItem('current_user');
        window.location.href = this.apiService.loginURL;
      },
      (error) => {
        console.log(error);
        window.location.href = this.apiService.loginURL;
      }
    );
  }
}

import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';


export interface AlertModel {
  title?: string;
  message: string;
}

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})

export class AlertComponent extends SimpleModalComponent<AlertModel, null> implements AlertModel {
  title: string = "";
  message: string = "";
  constructor() {
    super();
  }
}

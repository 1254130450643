<footer>
  <div class="row">
    <img src="assets/img/LogoSURA.svg" alt="" width="100" />
    <div class="right">
      <div class="phone">
        <p>Dudas sobre tus seguros</p>
        <p class="text-bold">55 5723-7999</p>
      </div>
      <div class="politics" (click)="goSocial('privacidad')">
        <p>Política de</p>
        <p class="text-bold">privacidad de datos</p>
      </div>
    </div>
  </div>
  <div class="f-bottom">
    <div class="copy-right">
      © 2021 SEGUROS SURA S.A. DE C.V.TODOS LOS DERECHOS RESERVADOS
    </div>
    <div class="social">
      <i class="fab fa-facebook" (click)="goSocial('facebook')"></i>
      <i class="fab fa-instagram" (click)="goSocial('instagram')"></i>
      <i class="fab fa-twitter" (click)="goSocial('twitter')"></i>
      <i class="fab fa-youtube" (click)="goSocial('youtube')"></i>
      <i class="fab fa-linkedin" (click)="goSocial('linkedin')"></i>
    </div>
  </div>
</footer>

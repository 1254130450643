<div class="steps-bar">
  <div
    class="step"
    *ngFor="let item of stepsArray; let i = index"
    [ngClass]="{ cumplido: i < actualStep - 1 }"
  >
    <!-- <div *ngIf="i != actualStep - 2" class="border"></div>
    <div *ngIf="i == actualStep - 2" class="border-actual"></div>
    <div *ngIf="i + 1 == actualStep - 1" class="percentage">
      {{ percentage }} %
    </div> -->
  </div>
</div>

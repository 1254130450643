<div class="menu">
  <div class="responsive-menu">
    <div
      (click)="activeMenu(item)"
      *ngFor="let item of options"
      [ngClass]="{ active: item == activeOption }"
      class="menu-link"
    >
      {{ item }}
    </div>
  </div>
</div>
<div class="d-none d-block-sm">
  <div style="position: relative">
    <button (click)="prev()" *ngIf="indes>0" class="prev">
      <i class="fas fa-angle-left"></i>
    </button>
    <button (click)="next()" *ngIf="indes<options.length - 2" class="next">
      <i class="fas fa-angle-right"></i>
    </button>
  </div>
</div>
<div class="menu-sm">
  <div class="responsive-menu">
    <div
      (click)="activeMenu(item)"
      *ngFor="let item of options | slice: indes:indes+2"
      [ngClass]="{ active: item == activeOption }"
      class="menu-link"
    >
      <label>{{ item }}</label>
    </div>
  </div>
</div>

<div
  class="form-control file-box"
  [ngClass]="{ even: index % 2 == 0, odd: index % 2 != 0 }"
  (click)="loadFile(uploader)"
>
  <input
    (change)="fileChangeEvent($event.target.files)"
    #uploader
    type="file"
    name="myImage"
  />
  <div class="rowContainer">
    <div style="display: flex">
      <div class="left mr-3">
        <div class="icon">
          <i *ngIf="item.status == 'notLoaded'" class="fas fa-upload"></i>
          <i *ngIf="item.status == 'loaded'" class="fas fa-check loaded"></i>
          <i *ngIf="item.status == 'error'" class="fas fa-times error"></i>
          <i *ngIf="item.status == 'loading'" class="fas fa-sync fa-spin"></i>
        </div>
        <div class="required" [ngClass]="{ notRequired: !item.obligatorio }">
          {{ required }}
        </div>
      </div>

      <div class="right">
        <div
          class="text fileName"
          style="margin-right: 5px"
          *ngIf="
            item.status == 'notLoaded' ||
            item.status == 'loading' ||
            item.status == 'loaded'
          "
        >
          {{ item.nombre }} - <span class="format">{{ item.formato }}</span>
        </div>
        <div
          class="text fileName"
          *ngIf="item.status == 'loaded' || item.status == 'error'"
          [ngClass]="{
            textLoaded: item.status == 'loaded',
            error: item.status == 'error'
          }"
        >
          {{
            file?.name.length > 50
              ? (file?.name | slice: 0:50) + "..."
              : file?.name
          }}
        </div>

        <div
          class="text"
          *ngIf="item.status == 'loaded' || item.status == 'error'"
          [ngClass]="{
            textLoaded: item.status == 'loaded',
            error: item.status == 'error'
          }"
        >
          {{ fileSize }}
        </div>
      </div>
    </div>
    <div class="icon">
      <i (click)="open(uploader)" class="fas fa-folder-open folder"></i>
      <i
        title="Descargar plantilla"
        *ngIf="item.plantilla"
        (click)="downloadPlantilla(item.plantilla)"
        class="far fa-file-alt download"
      ></i>
    </div>
  </div>
</div>
<div *ngIf="item.plantilla" class="row-right">
  <small (click)="downloadPlantilla(item.plantilla)" class="text-primary"
    >Descargar plantilla</small
  >
</div>

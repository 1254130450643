import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ActionTableBasic } from './action.interface';

@Component({
  selector: 'app-table-basic-actions',
  templateUrl: './table-basic-actions.component.html',
  styleUrls: ['./table-basic-actions.component.scss'],
})
export class TableBasicActionsComponent {
  @Input() title: any;
  @Input() tableHeaders: any;
  @Input() rows: any;
  @Input() type: any;
  @Input() stateType: any;
  @Input() actionColumn: Array<ActionTableBasic> = [];
  @Input() actions: Array<any> = [];
  _rows: any;
  @Output() action: EventEmitter<any>;
  @Output() view: EventEmitter<any> = new EventEmitter();
  @Output() setPageEvent: EventEmitter<any>;
  @Input() noDataError: any;
  @Output() eventActionColumn: EventEmitter<any> = new EventEmitter();

  @Input() serverSidePagination: boolean;
  @Input() totalRows;

  offset = 0;
  @Input() resetOffset;

  temp: Array<any> = [];
  selected: Array<any> = [];

  entriesVal: any;
  entries: Array<any> = [];
  validAction: boolean = false;

  ColumnMode = ColumnMode;
  SelectionType = SelectionType;

  constructor() {
    this.entries = [5, 10, 15];
    this.entriesVal = 5;
    this.selected = [];
    this.action = new EventEmitter();
    this.setPageEvent = new EventEmitter();
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rows) {
      this._rows = changes.rows.currentValue;
      this.temp = [...this._rows];
    }

    if (changes.resetOffset) this.offset = 0;
  }

  onSelect({ selected }: any) {
    console.log('Select Event', selected, this.selected);
  }

  onActivate(event: any) {
    if (event.type == 'click') {
      if (this.validAction === false) {
        console.log('data', event);
        this.view.emit({
          id: event.row.id,
          casosAprobados: event.row.casosAprobados,
          casosEvaluados: event.row.casosEvaluados,
        });
      } else {
        this.validAction = false;
      }
    }
  }

  actionEmit(name: string, value: any) {
    this.action.emit({ name: name, data: value });
  }

  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();

    let keys = [];
    for (let index = 0; index < this.tableHeaders.length; index++) {
      const element = this.tableHeaders[index].name;
      keys.push(element);
    }

    const temp = this.temp.filter(function (item) {
      for (let i = 0; i < keys.length; i++) {
        if (
          item[keys[i]].toString().toLowerCase().indexOf(val) !== -1 ||
          !val
        ) {
          return true;
        }
      }
    });

    this.rows = temp;
  }

  switchCheckbox(id: any) {
    let currentID = '';
    for (let index = 0; index < this.rows.length; index++) {
      currentID = 'card-' + index;
      document.getElementById(currentID)?.classList.remove('active');
    }
    document.getElementById('radio-' + id)?.click();
    document.getElementById('card-' + id)?.classList.add('active');
  }

  checkSelectable(event: any) {
    // console.log('Checking if selectable', event);
    return event.name !== 'Claudine Neal';
  }

  ngDoCheck(): void {
    if (this.rows.length > 0) {
      this._rows = [...this.rows];
    }
  }

  emitActionColumn(row, action) {
    this.validAction = true;
    if (!action.disabled)
      this.eventActionColumn.emit({ action: action.action, data: row });
  }

  setPage(e) {
    this.offset = e.offset;
    this.setPageEvent.emit(e);
  }
}

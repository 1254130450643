<div class="state-bar-content" *ngIf="type != 'reembolso'">
  <div class="name-box">
    <h4 *ngIf="data[0] != null">{{ data[0] }}</h4>
    <h4 *ngIf="data[0] == null">N/D</h4>
    <p>{{ header[0] }}</p>
  </div>
  <div class="poliza-box">
    <h4 *ngIf="data[1] != null">{{ data[1] }}</h4>
    <h4 *ngIf="data[1] == null">N/D</h4>
    <p>{{ header[1] }}</p>
  </div>
  <div class="date-box">
    <h4 *ngIf="data[2] != null">{{ data[2] }}</h4>
    <h4 *ngIf="data[2] == null">N/D</h4>
    <p>{{ header[2] }}</p>
  </div>
  <div class="state-box text-xlarge" *ngIf="type == 'vigente'">
    <div style="text-align: center" class="primary">
      <p>Vigente</p>
      <span class="text-small text-body"> {{ data[3] }}</span>
    </div>
  </div>
  <div
    class="state-box text-xlarge"
    *ngIf="type == 'Recibido / Pendiente de Asignación'"
  >
    <div style="text-align: center" class="text-danger">
      <p class="text-warning text-bold">{{ type }}</p>
    </div>
  </div>
  <div
    class="state-box text-xlarge"
    *ngIf="type == 'Asignado / Pendiente de Aceptación'"
  >
    <div style="text-align: center" class="text-danger">
      <p class="text-warning text-bold">{{ type }}</p>
    </div>
  </div>

  <div class="state-box text-xlarge" *ngIf="type == 'En Proceso'">
    <div style="text-align: center" class="text-warning">
      <p class="text-warning text-bold">{{ type }}</p>
    </div>
  </div>
  <div class="state-box text-xlarge" *ngIf="type == 'Aprobado'">
    <div style="text-align: center" class="text-warning">
      <p class="text-success text-bold">{{ type }}</p>
    </div>
  </div>
  <div class="state-box text-xlarge" *ngIf="type == 'Rechazado'">
    <div style="text-align: center" class="text-warning">
      <p class="text-danger text-bold">{{ type }}</p>
    </div>
  </div>
  <div class="state-box text-xlarge" *ngIf="type == 'Procesando Pago'">
    <div style="text-align: center" class="text-warning">
      <p class="text-warning text-bold">{{ type }}</p>
    </div>
  </div>
  <div class="state-box text-xlarge" *ngIf="type == 'Pago Efectuado'">
    <div style="text-align: center" class="text-warning">
      <p class="text-success text-bold">{{ type }}</p>
    </div>
  </div>
</div>

<!-- Reembolso -->
<div class="state-bar-content state-bar-reembolso" *ngIf="type == 'reembolso'">
  <div class="name-box">
    <h4 *ngIf="data[0] != null">{{ data[0] }}</h4>
    <h4 *ngIf="data[0] == null">N/D</h4>
    <p>{{ header[0] }}</p>
  </div>
  <div class="poliza-box">
    <h4>
      <span *ngIf="data[3] != null">{{ data[3] }}</span>
      <span *ngIf="data[3] == null">N/D</span> /
      <span *ngIf="data[1] != null">{{ data[1] }}</span>
      <span *ngIf="data[1] == null">N/D</span>
    </h4>
    <p>{{ header[1] }}</p>
  </div>
  <div class="date-box">
    <h4 *ngIf="data[2] != null">{{ data[2] }}</h4>
    <h4 *ngIf="data[2] == null">N/D</h4>
    <p>{{ header[2] }}</p>
  </div>
  <div class="state-box text-xlarge" *ngIf="type == 'vigente'">
    <div style="text-align: center" class="primary">
      <p>Vigente</p>
      <span class="text-small text-body"> {{ data[3] }}</span>
    </div>
  </div>
  <div
    class="state-box text-xlarge"
    *ngIf="type == 'Recibido / Pendiente de Asignación'"
  >
    <div style="text-align: center" class="text-danger">
      <p class="text-warning text-bold">{{ type }}</p>
    </div>
  </div>

  <div class="state-box">
    <div class="box-reembolso">
      <div *ngIf="status.info == 'N/A'" class="text-bold text-xlarge info">
        <p>{{ status.info }}</p>
      </div>

      <div
        *ngIf="status.info != 'N/A'"
        [class]="'text-bold info ' + status.color"
        style="font-size: 26px"
      >
        <p>{{ status.info }}</p>
      </div>

      <p class="updated">Actualizado</p>
      <p class="time-row">
        <span>{{ status.fecha }}</span
        ><span>{{ status.hora }}</span>
      </p>
    </div>
  </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-poliza-card',
  templateUrl: './poliza-card.component.html',
  styleUrls: ['./poliza-card.component.scss'],
})
export class PolizaCardComponent implements OnInit {
  @Input() type: any;
  @Input() poliza: any;
  @Output() selectPoliza = new EventEmitter<any>();
  descProducto: string;
  pct: any;

  ngOnInit(): void {
    this.descProducto = this.poliza?.descProducto?.toLowerCase();
    // console.log(this.poliza?.descProducto);

    let pct;

    if (this.poliza)
      if (this.poliza.diasRestantes && this.poliza.diasTotales)
        pct = (this.poliza.diasRestantes / this.poliza.diasTotales) * 100;
      else pct = 0;

    this.pct = 'width: ' + '' + pct + '%';

    if (pct === 0) {
      this.pct = 'width: ' + '' + '100' + '%; background-color: #e4002b;';
    }
  }

  verDetalle() {
    this.selectPoliza.emit(this.poliza);
  }
}
